import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Tabs,
  Tab,
  withStyles,
} from '@material-ui/core'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Card from './Card'
import { supplierActions } from '../../redux/supplier'
import Members from './Members'
import Prices from './Prices'

const styles = _theme => ({
  root: {
    margin: '65px auto',
    maxWidth: 600,
    background: 'white',
  },
})

class Supplier extends Component {
  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
  }

  componentDidMount() {
    const {
      history,
      location,
      id,
    } = this.props

    if (id && location.pathname === '/new-supplier') {
      history.replace(`/supplier/${id}`)
    }
  }

  componentDidUpdate() {
    const {
      id,
      history,
      location,
    } = this.props

    if (id && location.pathname === '/new-supplier') {
      history.replace(`/supplier/${id}`)
    }
  }

  onChange(_event, tab) {
    const { change } = this.props

    change({ name: 'tab', value: tab })
  }

  render() {
    const { tab, classes, location } = this.props
    const condition = location.pathname === '/new-supplier';

    const TabPanel = () => {
      if (!condition) {
        switch (tab) {
          case 'card':
            return <Card />
          case 'members':
            return <Members />
          case 'prices':
            return <Prices />
          default:
            return null
        }
      } else {
        switch (tab) {
          case 'card':
            return <Card />
          default:
            return null
        }
      }
    }


    return (
      <div className={classes.root}>
        <Tabs
          value={tab}
          variant="fullWidth"
          onChange={this.onChange}
        >
          <Tab value="card" label="Карточка" />
          { !condition && <Tab value="members" label="Представители" /> }
          { !condition && <Tab value="prices" label="Прайслист" /> }

        </Tabs>

        {TabPanel()}
      </div>
    )
  }
}

Supplier.propTypes = {
  id: PropTypes.string,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  change: PropTypes.func.isRequired,
  tab: PropTypes.string,
}

Supplier.defaultProps = {
  id: null,
  tab: 'card',
}

const mapStateToProps = state => ({
  id: state.supplier.id,
  tab: state.supplier.tab,
})

export default withRouter(connect(mapStateToProps, {
  change: supplierActions.change,
})(withStyles(styles)(Supplier)))
