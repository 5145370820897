import * as types from './types'

const initialState = {
  user: {},
}

export default function (state = initialState, action) {
  const { payload } = action

  switch (action.type) {
    case types.GET_SUPPLIERS_SUCCESS:
      return {
        suppliers: payload,
      }

    case types.GET_SUPPLIERS_ERROR:
      return { ...state, error: payload.status }

    case types.CHANGE_SUPPLIER:
      return {
        ...state,
        [payload.name]: payload.value,
      }
    case types.CREATE_SUPPLIER_SUCCESS:
      return {
        ...state,
        ...payload,
      }
    case types.CREATE_SUPPLIER_ERROR:
      return { ...state, error: payload.status }
    case types.GET_SUPPLIER_SUCCESS:
      return {
        ...state,
        ...payload,
      }
    case types.GET_SUPPLIER_ERROR:
      return { ...state, error: payload.status }
    case types.UPDATE_SUPPLIER_SUCCESS:
      return {
        ...state,
        ...payload,
      }
    case types.UPDATE_SUPPLIER_ERROR:
      return { ...state, error: payload.status }

    case types.GET_SUPPLIER_MEMBERS_SUCCESS:
      return {
        ...state,
        members: payload,
        member: {},
      }

    case types.GET_SUPPLIER_MEMBERS_ERROR:
      return { ...state, error: payload.status }

    case types.CHANGE_SUPPLIER_MEMBER:
      return {
        ...state,
        member: {
          ...state.member,
          [payload.name]: payload.value,
        },
      }

    case types.CREATE_SUPPLIER_MEMBER_SUCCESS:
      return {
        ...state,
        member: {
          ...payload,
        },
      }

    case types.CREATE_SUPPLIER_MEMBER_ERROR:
      return { ...state, error: payload.status }

    case types.GET_SUPPLIER_MEMBER_SUCCESS:
      return {
        ...state,
        member: {
          ...payload,
        },
      }
    case types.GET_SUPPLIER_MEMBER_ERROR:
      return { ...state, error: payload.status }

    case types.UPDATE_SUPPLIER_MEMBER_SUCCESS:
      return {
        ...state,
        member: {
          ...payload,
        },
      }
    case types.UPDATE_SUPPLIER_MEMBER_ERROR:
      return { ...state, error: payload.status }

    case types.GET_SUPPLIER_PRICES_SUCCESS:
      return {
        ...state,
        prices: payload,
        price: {},
      }

    case types.GET_SUPPLIER_PRICES_ERROR:
      return { ...state, error: payload.status }

    case types.CHANGE_SUPPLIER_PRICE:
      return {
        ...state,
        price: {
          ...state.price,
          [payload.name]: payload.value,
        },
      }

    case types.CREATE_SUPPLIER_PRICE_SUCCESS:
      return {
        ...state,
        price: {
          ...payload,
        },
      }

    case types.CREATE_SUPPLIER_PRICE_ERROR:
      return { ...state, error: payload.status }

    case types.GET_SUPPLIER_PRICE_SUCCESS:
      return {
        ...state,
        price: {
          ...payload,
        },
      }
    case types.GET_SUPPLIER_PRICE_ERROR:
      return { ...state, error: payload.status }

    case types.UPDATE_SUPPLIER_PRICE_SUCCESS:
      return {
        ...state,
        price: {
          ...payload,
        },
      }
    case types.UPDATE_SUPPLIER_PRICE_ERROR:
      return { ...state, error: payload.status }

    default:
      return {
        ...state,
      }
  }
}
