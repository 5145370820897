import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  TextField,
  Paper,
  withStyles,
  Button,
  InputAdornment,
  InputLabel,
  FormControl,
  Input,
  FormHelperText,
} from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { sessionActions } from '../../redux/session'
import PhoneInput from '../../components/PhoneInput'

const styles = theme => ({
  paper: {
    padding: theme.spacing(5, 3),
    position: 'fixed',
    top: 56,
    bottom: 0,
    width: '100%',
    maxWidth: 600,
    margin: '0 auto',
    left: 0,
    right: 0,
  },
  form: {
    '& .MuiFormControl-root': {
      width: '100%',
      marginBottom: theme.spacing(3),
    },
    '& .MuiButton-root': {
      marginTop: theme.spacing(3),
    },
  },
})

class Login extends Component {
  constructor() {
    super()
    this.state = {
      phone: '',
      password: '',
      formChanged: false,
    }

    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount() {
    const { token, history } = this.props

    if (token) {
      return history.push('/')
    }

    return this.setState({ formChanged: true })
  }

  componentDidUpdate(prevProps) {
    const { token, history } = this.props

    if (token && token !== prevProps.token) {
      history.push('/')
    }
  }

  onSubmit(event) {
    const { login } = this.props
    const { phone, password } = this.state

    event.preventDefault()

    login({ phone, password })

    this.setState({ formChanged: false })
  }

  render() {
    const { classes, error } = this.props
    const { phone, password, formChanged } = this.state

    const onChange = name => event => {
      this.setState({ [name]: event.target.value, formChanged: true })
    }

    const isError = field => {
      if (formChanged) return null

      let helperText

      if (field === 'phone' && error === 404) {
        helperText = 'Номер не найден'
      }

      if (field === 'password' && error === 403) {
        helperText = 'Неверный пароль'
      }

      return helperText
    }

    return (
      <Paper elevation={0} className={classes.paper}>
        <form onSubmit={this.onSubmit} className={classes.form}>
          <FormControl error={!!isError('phone')}>
            <InputLabel>Телефон</InputLabel>
            <Input
              value={phone}
              onChange={onChange('phone')}
              startAdornment={<InputAdornment position="start">+7</InputAdornment>}
              inputComponent={PhoneInput}
            />
            <FormHelperText>{isError('phone')}</FormHelperText>
          </FormControl>

          <TextField error={!!isError('password')} label="Пароль" type="password" value={password} onChange={onChange('password')} helperText={isError('password')} />

          <Button variant="contained" color="secondary" type="submit" onClick={this.onSubmit}>
            Войти
          </Button>
        </form>
      </Paper>
    )
  }
}

Login.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  token: PropTypes.string,
  error: PropTypes.number,
  // location: PropTypes.object,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  login: PropTypes.func.isRequired,
}

Login.defaultProps = {
  token: null,
  error: null,
}

const mapStateToProps = state => ({
  token: state.session.token,
  error: state.session.error,
})

export default withRouter(connect(mapStateToProps, {
  login: sessionActions.login,
})(withStyles(styles)(Login)))
