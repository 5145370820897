export const GET_PRODUCTS = 'GET_PRODUCTS'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR'

export const CHANGE_PRODUCT = 'CHANGE_PRODUCT'

export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS'
export const CREATE_PRODUCT_ERROR = 'CREATE_PRODUCT_ERROR'

export const GET_PRODUCT = 'GET_PRODUCT'
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS'
export const GET_PRODUCT_ERROR = 'GET_PRODUCT_ERROR'

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_ERROR = 'UPDATE_PRODUCT_ERROR'

export const UPLOAD_PRODUCT_PHOTO = 'UPLOAD_PRODUCT_PHOTO'
export const UPLOAD_PRODUCT_PHOTO_SUCCESS = 'UPLOAD_PRODUCT_PHOTO_SUCCESS'
export const UPLOAD_PRODUCT_PHOTO_ERROR = 'UPLOAD_PRODUCT_PHOTO_ERROR'

export const GET_UNITS = 'GET_UNITS'
export const GET_UNITS_SUCCESS = 'GET_UNITS_SUCCESS'
export const GET_UNITS_ERROR = 'GET_UNITS_ERROR'

export const GET_GROUPS = 'GET_GROUPS'
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS'
export const GET_GROUPS_ERROR = 'GET_GROUPS_ERROR'
