import * as types from './types'

export const setOpen = (payload) => ({
  type: types.SET_NOTIFICATION_OPEN,
  payload,
})

export const notify = (payload) => ({
  type: types.NOTIFY,
  payload,
})

export const getNotifications = (payload) => ({
  type: types.GET_NOTIFICATIONS,
  payload,
})
