import 'date-fns'
import React, { useEffect } from 'react'
import {
  TableContainer,
  makeStyles,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Typography,
  TextField,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import ruLocale from 'date-fns/locale/ru'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import dateFormat from 'dateformat'
import { orderActions } from '../../redux/order'
import { productActions } from '../../redux/product'
import { supplierActions } from '../../redux/supplier'
import { describeStatus } from '../../helpers'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    position: 'fixed',
    bottom: 0,
    top: 56,
    width: '100%',
    maxWidth: 600,
    left: 0,
    right: 0,
    background: 'white',
    margin: '0 auto',
    overflowY: 'scroll',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  total: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
  },
  form: {
    '& .MuiFormControl-root': {
      width: '100%',
      marginBottom: theme.spacing(3),
    },
    '& .MuiButton-root': {
      marginTop: theme.spacing(3),
    },
  },
  title: {
    width: '250px',
    display: 'inline',
    marginRight: theme.spacing(2),
  },
  productGroup: {
    width: '250px',
    display: 'inline-block',
  },
  formControl: {
    margin: theme.spacing(1),
  },
}))

function Report({ apiSet, getReport, report, groups, branches, products, getProducts, getSuppliers, suppliers }) {
  const classes = useStyles()

  const [periodStartDate, setPeriodStartDate] =
    React.useState(new Date() - 30 * 24 * 60 * 60 * 1000)
  const [periodEndDate, setPeriodEndDate] = React.useState(new Date())
  const [productGroup, setProductGroup] = React.useState('')
  const [product, setProduct] = React.useState(null)
  const [supplier, setSupplier] = React.useState('')
  const [linkedBranch, setLinkedBranch] = React.useState('')

  const [reports, changeReports] = React.useState(report)

  const total = reports.reduce((a, b) => a + (b.value * b.quantity), 0)

  useEffect(() => {
    if (apiSet && report.length === 0) {
      getReport()
      getProducts()
      getSuppliers()
    }
    // if (reports.length === 0 && report.length > 0) {
    //   changeReports(report)
    // }
  }, [apiSet, getReport, getProducts, getSuppliers, report])

  useEffect(() => {
    const performSorting = () => {
      let sortedReports = report

      if (productGroup) {
        sortedReports = sortedReports.filter(d => d.group._id === productGroup)
      }


      if (linkedBranch) {
        sortedReports = sortedReports.filter(d => d.branch._id === linkedBranch)
      }

      if (supplier) {
        sortedReports = sortedReports.filter(d => d.supplier === supplier)
      }

      if (product) {
        sortedReports = sortedReports.filter(d => d.product === product.name)
      }

      sortedReports = sortedReports.filter(d =>
        (d.orderDate > dateFormat(periodStartDate, 'isoDateTime') &&
        (d.orderDate < dateFormat(periodEndDate, 'isoDateTime'))))

      changeReports(sortedReports)
    }
    performSorting()
  }, [periodStartDate, periodEndDate, productGroup, product, supplier, linkedBranch, report])


  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider locale={ruLocale} utils={DateFnsUtils}>
        <Grid container justify="space-around">
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            margin="normal"
            format="dd.MM.yyyy"
            id="date-picker-inline"
            label="Дата начала периода"
            value={periodStartDate}
            onChange={date => setPeriodStartDate(date)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="Дата конца периода"
            format="dd.MM.yyyy"
            value={periodEndDate}
            onChange={date => setPeriodEndDate(date)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider>

      <FormControl className={classes.formControl}>
        <Autocomplete
          noOptionsText="Нет товара"
          id="combo-box-demo"
          options={productGroup ?
            products.filter(p => p.group === productGroup) : products}
          getOptionLabel={(option) => (option.name ? option.name : '')}
          style={{ width: 250 }}
          renderInput={params => <TextField {...params} label="Товар" />}
          onChange={(event, newValue) => {
            setProduct(newValue)
          }}
          getOptionSelected={(option, value) => option.name === value.name }
        />
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="unit">Объект</InputLabel>
        <Select
          native
          // value={unit}
          className={classes.productGroup}
          onChange={event => setLinkedBranch(event.target.value)}
        >
          <option value=""> </option>
          {branches?.map(u => <option value={u.id} key={u.id}>{u.name}</option>)}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="unit">Товарная группа</InputLabel>
        <Select
          native
          // value={unit}
          className={classes.productGroup}
          onChange={event => setProductGroup(event.target.value)}
        >
          <option value=""> </option>
          {groups.map(u => <option value={u.id} key={u.id}>{u.name}</option>)}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="unit">Поставщик</InputLabel>
        <Select
          native
          // value={unit}
          className={classes.productGroup}
          onChange={event => setSupplier(event.target.value)}
        >
          <option value=""> </option>
          {suppliers.map(u => <option value={u.name} key={u.id}>{u.name}</option>)}
        </Select>
      </FormControl>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Продукт</TableCell>
              <TableCell align="right">Ед. изм.</TableCell>
              <TableCell align="right">Поставщик</TableCell>
              <TableCell align="right">Дата заявки</TableCell>
              <TableCell align="right">Количество</TableCell>
              <TableCell align="right">Цена</TableCell>
              <TableCell align="right">Сумма</TableCell>
              <TableCell align="right">Статус</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reports && reports.length ? reports.map((d) => {
              const orderDate = new Date(d.orderDate)

              return (
                <TableRow key={d.id}>
                  <TableCell component="th" scope="row">
                    {d.product}
                  </TableCell>
                  <TableCell align="right">{d.unit}</TableCell>
                  <TableCell align="right">{d.supplier}</TableCell>
                  <TableCell align="right">{orderDate.toLocaleString()}</TableCell>
                  <TableCell align="right">{d.quantity}</TableCell>
                  <TableCell align="right">{d.value}</TableCell>
                  <TableCell align="right">{d.quantity * d.value}</TableCell>
                  <TableCell align="right">{describeStatus(d.status).text}</TableCell>
                </TableRow>
              )
            }) : (
              <TableRow>
                <TableCell component="th" scope="row" align="center" variant="body">
                  Отсутствует товар
                </TableCell>
                <TableCell component="th" scope="row" align="center" variant="body">
                  -
                </TableCell>
                <TableCell component="th" scope="row" align="center" variant="body">
                  -
                </TableCell>
                <TableCell component="th" scope="row" align="center" variant="body">
                  -
                </TableCell>
                <TableCell component="th" scope="row" align="center" variant="body">
                  -
                </TableCell>
                <TableCell component="th" scope="row" align="center" variant="body">
                  -
                </TableCell>
                <TableCell component="th" scope="row" align="center" variant="body">
                  -
                </TableCell>
                <TableCell component="th" scope="row" align="center" variant="body">
                  -
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Paper className={classes.total}>
        <Typography variant="body1">
          Итого
        </Typography>
        <Typography variant="body2">
          { new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'KZT',
            minimumFractionDigits: 0,
          }).format(total)}
        </Typography>
      </Paper>
    </div>
  )
}

Report.propTypes = {
  apiSet: PropTypes.bool,
  getReport: PropTypes.func.isRequired,
  getSuppliers: PropTypes.func.isRequired,
  suppliers: PropTypes.arrayOf(PropTypes.object),
  report: PropTypes.arrayOf(PropTypes.object),
  groups: PropTypes.arrayOf(PropTypes.object),
  branches: PropTypes.arrayOf(PropTypes.object),
  products: PropTypes.arrayOf(PropTypes.object),
  getProducts: PropTypes.func.isRequired,
}

Report.defaultProps = {
  apiSet: false,
  report: [],
  groups: [],
  branches: [],
  products: [],
  suppliers: [],
}

const mapStateToProps = state => ({
  apiSet: state.session.apiSet,
  report: state.order.report,
  groups: state.product.groups,
  products: state.product.products,
  branches: state.branch.branches,
  suppliers: state.supplier.suppliers,
})

export default connect(mapStateToProps, {
  getReport: orderActions.getReport,
  getProducts: productActions.getProducts,
  getSuppliers: supplierActions.getSuppliers,
})(Report)
