export function formatPhone(phone) {
  return `(${phone.substr(0, 3)}) ${phone.substr(3, 3)} ${phone.substr(
    6,
    2,
  )} ${phone.substr(8, 2)}`
}

export const sameProduct = (item, pr) =>
  item.product.id === pr.product.id && item.unit.id === pr.unit.id

export const describeStatus = (status) => {
  switch (status) {
    case 'new':
      return { text: 'Создана', color: 'warning' }
    case 'accepted':
      return { text: 'Принята к исполнению', color: 'warning' }
    case 'changed':
      return { text: 'Изменено поставщиком', color: 'warning' }
    case 'changes_acquired':
      return { text: 'Изменения приняты', color: 'warning' }
    case 'changes_rejected':
      return { text: 'Изменения отклонены', color: 'error' }
    case 'rejected':
      return { text: 'Отклонена поставщиком', color: 'error' }
    case 'acquired':
      return { text: 'Товар принят', color: 'secondary' }
    case 'returned':
      return { text: 'Товар возвращен', color: 'error' }
    case 'canceled':
      return { text: 'Отменена', color: 'secondary' }
    default:
      return { text: 'Статус неизвестен', color: 'error' }
  }
}
