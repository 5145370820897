import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  TextField, Paper, withStyles, Button, InputAdornment, Typography,
} from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { sessionActions } from '../../redux/session'
import api from '../../api/api'
import PhoneInput from '../../components/PhoneInput'

const styles = theme => ({
  paper: {
    padding: theme.spacing(3, 3),
    position: 'fixed',
    top: 56,
    bottom: 0,
    width: '100%',
    maxWidth: 600,
    margin: '0 auto',
    left: 0,
    right: 0,
  },
  form: {
    marginTop: 20,
    '& .MuiTextField-root': {
      width: '100%',
      marginBottom: theme.spacing(3),
    },
    '& .MuiButton-root': {
      marginTop: theme.spacing(3),
    },
  },
})

class SetPassword extends Component {
  constructor() {
    super()
    this.state = {
      password: '',
      repeat: '',
      formChanged: false,
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.formValid = this.formValid.bind(this)
  }

  componentDidMount() {
    const { match, setAPIAuth, getUser } = this.props

    const { token } = match.params

    api.defaults.headers.common.Authorization = token

    setAPIAuth(true)

    getUser()

    return this.setState({ formChanged: true })
  }

  componentDidUpdate(prevProps) {
    const { error, history, passwordSet } = this.props

    if (error && error !== prevProps.error && error === 404) {
      history.replace('/404')
    }

    if (passwordSet && passwordSet !== prevProps.passwordSet) {
      history.replace('/')
    }
  }

  onSubmit(event) {
    const { password } = this.state
    const { setPassword } = this.props
    event.preventDefault()

    setPassword({
      password,
    })
  }

  formValid() {
    const { password, repeat } = this.state

    if (!password) return false
    if (!repeat) return false

    if (password !== repeat) return false

    return true
  }

  render() {
    const {
      classes, error, phone, name,
    } = this.props
    const {
      password, repeat, formChanged,
    } = this.state

    const onChange = fieldName => event => {
      this.setState({ [fieldName]: event.target.value, formChanged: true })
    }

    const isError = field => {
      if (formChanged) return null

      let helperText

      if (field === 'password' && error === 403) {
        helperText = 'Неверный пароль'
      }

      return helperText
    }

    const welcomeText = `Здравствуйте, ${name}. Добро пожаловать в Chechil Закупки!`

    return (
      <Paper elevation={0} className={classes.paper}>
        <Typography variant="subtitle1">{welcomeText}</Typography>

        <form onSubmit={this.onSubmit} className={classes.form}>
          <TextField
            label="Телефон"
            value={phone}
            InputProps={{
              startAdornment: <InputAdornment position="start">+7</InputAdornment>,
              inputComponent: PhoneInput,
            }}
            disabled
          />
          <TextField error={!!isError('password')} label="Пароль" type="password" value={password} onChange={onChange('password')} helperText={isError('password')} />

          <TextField error={!!isError('repeat')} label="Повтор пароля" type="password" value={repeat} onChange={onChange('repeat')} helperText={isError('repeat')} />

          <Button variant="contained" color="secondary" type="submit" disabled={!this.formValid()}>
            Сохранить
          </Button>
        </form>
      </Paper>
    )
  }
}

SetPassword.propTypes = {
  error: PropTypes.number,
  // location: PropTypes.object,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  setAPIAuth: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  phone: PropTypes.string,
  name: PropTypes.string,
  passwordSet: PropTypes.bool,
}

SetPassword.defaultProps = {
  error: null,
  phone: '',
  name: '',
  passwordSet: false,
}

const mapStateToProps = state => ({
  error: state.session.error,
  phone: state.session.phone,
  name: state.session.name,
  passwordSet: state.session.passwordSet,
})

export default withRouter(connect(mapStateToProps, {
  setAPIAuth: sessionActions.setAPIAuth,
  getUser: sessionActions.get,
  setPassword: sessionActions.setPassword,
})(withStyles(styles)(SetPassword)))
