import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  TextField,
  withStyles,
} from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Close as CloseIcon } from '@material-ui/icons'
import { orderActions } from '../../redux/order'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      marginRight: theme.spacing(3),
      marginLeft: theme.spacing(3),
    },
  },
  textField: {
    width: '85%',
  },
}));

const styles = () => ({
  button: {
    width: '50px',
  },
})

const statusText = status => {
  switch (status) {
    case 'changes_acquired':
      return 'Вы действительно хотите принять изменения?'
    case 'changes_rejected':
      return 'Вы действительно хотите отклонить изменения?'
    default:
      return null
  }
}

function OrderChangesDialog(props) {
  const comment = useStyles()
  const [text, setText] = React.useState('');
  const { open, classes, status, onClose, notifySupplier, match } = props

  const onSubmit = (event) => {
    notifySupplier({
      orderId: match.params.id,
      status,
      comment: text
    })

    onClose(event)
  }

  const handleChange = (event) => {
      setText(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" aria-valuetext="center">
        {status ? statusText(status) : null}
        <IconButton className={classes.button} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

       <form className={comment.root} noValidate autoComplete="off">
            <TextField
              d="standard-error-helper-text"
              label="Комментарий"
              multiline
              value={text}
              onChange={handleChange}
              className={comment.textField}
              helperText="Заполните поле."
            />
       </form>

      <DialogActions>
        <Button
            onClick={onSubmit}
            color="primary"
            autoFocus>
          Да
        </Button>
        <Button onClick={onClose} color="primary" autoFocus>
          Нет
        </Button>
      </DialogActions>
    </Dialog>
  )
}

OrderChangesDialog.propTypes = {
  open: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  onClose: PropTypes.func.isRequired,
  status: PropTypes.string,
  notifySupplier: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
}

OrderChangesDialog.defaultProps = {
  open: false,
  status: '',
}

export default withRouter(connect(null, {
  notifySupplier: orderActions.notifySupplierStatus,
})(withStyles(styles)(OrderChangesDialog)))
