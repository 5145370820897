import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'

function PhoneInput(props) {
  const {
    inputRef, onChange, className, value, onFocus, name, disabled,
  } = props

  return (
    <NumberFormat
      className={className}
      value={value}
      onFocus={onFocus}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
            name,
          },
        })
      }}
      format="(###) ### ## ##"
      isNumericString
      type="tel"
      disabled={disabled}
    />
  )
}

PhoneInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onFocus: PropTypes.func.isRequired,
  name: PropTypes.string,
  disabled: PropTypes.bool,
}

PhoneInput.defaultProps = {
  name: 'phone',
  disabled: false,
}

export default PhoneInput
