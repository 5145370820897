import React from 'react'
import { Route, Redirect, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Navbar from './Navbar'
import Login from './Login'
import Users from './Users'
import User from './User'
import Suppliers from './Suppliers'
import Supplier from './Supplier'
import SetPassword from './SetPassword'
import NotFound from './NotFound'
import Notification from './Notification'
import { sessionActions } from '../redux/session'
import Member from './Supplier/Member'
import Price from './Supplier/Price'
import Products from './Products'
import Product from './Product'
import Orders from './Orders'
import Market from './Market'
import Cart from './Cart'
import Order from './Order'
import Report from './Report'
import ArchivedOrders from './ArchivedOrders'
import ArchivedOrderView from './ArhivedOrderView'
import MainOrder from './Order/MainOrder'
import Notifications from './Notifications'

class Main extends React.Component {
  componentDidUpdate(prevProps) {
    const { error, location, clearSession } = this.props

    const ignoreRoutes = [
      '/set-password',
      '/login',
    ]

    const isIngoreRoute = ignoreRoutes.some(ir => location.pathname.includes(ir))

    if (error && error !== prevProps.error && error === 404 && !isIngoreRoute) {
      clearSession()
    }
  }

  authorized(comp, query) {
    const { token, location } = this.props
    let serachParam = location.search
    if (serachParam && query === 'orderSuplier') {
      serachParam = serachParam.slice(3)
      return comp
    }
    if (token) {
      return comp
    }

    return () => <Redirect to="/login" />
  }

  render() {
    return (
      <div>
        <Navbar />

        <Route path="/" exact render={() => <Redirect to="/orders" />} />
        <Route path="/login" component={Login} />
        <Route path="/set-password/:token" component={SetPassword} />
        <Route path="/users" component={this.authorized(Users)} />
        <Route path="/new-user" component={this.authorized(User)} />
        <Route path="/user/:id" component={this.authorized(User)} />
        <Route path="/suppliers" component={this.authorized(Suppliers)} />
        <Route path="/new-supplier" component={this.authorized(Supplier)} />
        <Route path="/supplier/:id" component={this.authorized(Supplier)} />
        <Route path="/new-member" component={this.authorized(Member)} />
        <Route path="/member/:id" component={this.authorized(Member)} />
        <Route path="/new-price" component={this.authorized(Price)} />
        <Route path="/price/:id" component={this.authorized(Price)} />

        <Route path="/products" component={this.authorized(Products)} />
        <Route path="/new-product" component={this.authorized(Product)} />
        <Route path="/product/:id" component={this.authorized(Product)} />

        <Route path="/archives/order/:id" component={this.authorized(ArchivedOrderView)} />
        <Route path="/archive" component={this.authorized(ArchivedOrders)} />
        <Route path="/orders" component={this.authorized(Orders)} />
        <Route path="/notifications" component={this.authorized(Notifications)} />
        <Route path="/market" component={this.authorized(Market)} />
        <Route path="/cart" component={this.authorized(Cart)} />
        <Route path="/order/:id" component={this.authorized(Order, 'orderSuplier')} />
        <Route path="/main-order/:id" component={this.authorized(MainOrder)} />
        <Route path="/report" component={this.authorized(Report)} />

        <Route path="/404" component={NotFound} />

        <Notification />
      </div>
    )
  }
}

Main.propTypes = {
  token: PropTypes.string,
  error: PropTypes.number,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  clearSession: PropTypes.func.isRequired,
}

Main.defaultProps = {
  token: null,
  error: null,
}

const mapStateToProps = state => ({
  token: state.session.token,
  error: state.session.error,
})

export default withRouter(connect(mapStateToProps, {
  clearSession: sessionActions.clear,
})(Main))
