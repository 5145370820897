export const GET_SUPPLIERS = 'GET_SUPPLIERS'
export const GET_SUPPLIERS_SUCCESS = 'GET_SUPPLIERS_SUCCESS'
export const GET_SUPPLIERS_ERROR = 'GET_SUPPLIERS_ERROR'

export const CHANGE_SUPPLIER = 'CHANGE_SUPPLIER'

export const CREATE_SUPPLIER = 'CREATE_SUPPLIER'
export const CREATE_SUPPLIER_SUCCESS = 'CREATE_SUPPLIER_SUCCESS'
export const CREATE_SUPPLIER_ERROR = 'CREATE_SUPPLIER_ERROR'

export const GET_SUPPLIER = 'GET_SUPPLIER'
export const GET_SUPPLIER_SUCCESS = 'GET_SUPPLIER_SUCCESS'
export const GET_SUPPLIER_ERROR = 'GET_SUPPLIER_ERROR'

export const UPDATE_SUPPLIER = 'UPDATE_SUPPLIER'
export const UPDATE_SUPPLIER_SUCCESS = 'UPDATE_SUPPLIER_SUCCESS'
export const UPDATE_SUPPLIER_ERROR = 'UPDATE_SUPPLIER_ERROR'

export const GET_SUPPLIER_MEMBERS = 'GET_SUPPLIER_MEMBERS'
export const GET_SUPPLIER_MEMBERS_SUCCESS = 'GET_SUPPLIER_MEMBERS_SUCCESS'
export const GET_SUPPLIER_MEMBERS_ERROR = 'GET_SUPPLIER_MEMBERS_ERROR'

export const CHANGE_SUPPLIER_MEMBER = 'CHANGE_SUPPLIER_MEMBER'

export const CREATE_SUPPLIER_MEMBER = 'CREATE_SUPPLIER_MEMBER'
export const CREATE_SUPPLIER_MEMBER_SUCCESS = 'CREATE_SUPPLIER_MEMBER_SUCCESS'
export const CREATE_SUPPLIER_MEMBER_ERROR = 'CREATE_SUPPLIER_MEMBER_ERROR'

export const GET_SUPPLIER_MEMBER = 'GET_SUPPLIER_MEMBER'
export const GET_SUPPLIER_MEMBER_SUCCESS = 'GET_SUPPLIER_MEMBER_SUCCESS'
export const GET_SUPPLIER_MEMBER_ERROR = 'GET_SUPPLIER_MEMBER_ERROR'

export const UPDATE_SUPPLIER_MEMBER = 'UPDATE_SUPPLIER_MEMBER'
export const UPDATE_SUPPLIER_MEMBER_SUCCESS = 'UPDATE_SUPPLIER_MEMBER_SUCCESS'
export const UPDATE_SUPPLIER_MEMBER_ERROR = 'UPDATE_SUPPLIER_MEMBER_ERROR'

export const GET_SUPPLIER_PRICES = 'GET_SUPPLIER_PRICES'
export const GET_SUPPLIER_PRICES_SUCCESS = 'GET_SUPPLIER_PRICES_SUCCESS'
export const GET_SUPPLIER_PRICES_ERROR = 'GET_SUPPLIER_PRICES_ERROR'

export const CHANGE_SUPPLIER_PRICE = 'CHANGE_SUPPLIER_PRICE'

export const CREATE_SUPPLIER_PRICE = 'CREATE_SUPPLIER_PRICE'
export const CREATE_SUPPLIER_PRICE_SUCCESS = 'CREATE_SUPPLIER_PRICE_SUCCESS'
export const CREATE_SUPPLIER_PRICE_ERROR = 'CREATE_SUPPLIER_PRICE_ERROR'

export const GET_SUPPLIER_PRICE = 'GET_SUPPLIER_PRICE'
export const GET_SUPPLIER_PRICE_SUCCESS = 'GET_SUPPLIER_PRICE_SUCCESS'
export const GET_SUPPLIER_PRICE_ERROR = 'GET_SUPPLIER_PRICE_ERROR'

export const UPDATE_SUPPLIER_PRICE = 'UPDATE_SUPPLIER_PRICE'
export const UPDATE_SUPPLIER_PRICE_SUCCESS = 'UPDATE_SUPPLIER_PRICE_SUCCESS'
export const UPDATE_SUPPLIER_PRICE_ERROR = 'UPDATE_SUPPLIER_PRICE_ERROR'
