import * as types from './types'

const initialState = {
  user: {},
}

export default function (state = initialState, action) {
  const { payload } = action

  switch (action.type) {
    case types.GET_USERS_SUCCESS:
      return {
        ...state,
        users: payload,
        user: {},
      }
    case types.GET_USERS_ERROR:
      return { ...state, error: payload.status }
    case types.CREATE_USER:
      return {
        ...state,
      }
    case types.CREATE_USER_SUCCESS:
      return {
        ...state,
        user: payload,
      }
    case types.CREATE_USER_ERROR:
      return { ...state, error: payload.status }
    case types.UPDATE_USER:
      return {
        ...state,
      }
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: payload,
      }
    case types.UPDATE_USER_ERROR:
      return { ...state, error: payload.status }

    case types.DELETE_USER_SUCCESS:
        return {
          ...state,
          user: payload,
        }
    case types.DELETE_USER_ERROR:
        return { ...state, error: payload.status }
    case types.GET_USER_SUCCESS:
      return {
        ...state,
        user: payload,
      }
    case types.GET_USER_ERROR:
      return { ...state, error: payload.status }
    case types.CHANGE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          [payload.name]: payload.value,
        },
      }
    default:
      return {
        ...state,
      }
  }
}
