import React from 'react'
import PropTypes from 'prop-types'
import {
  Backdrop,
  Card,
  CardContent,
  Typography,
  withStyles,
  TextField,
  Button,
  IconButton,
} from '@material-ui/core'
import {
  Close as CloseIcon,
} from '@material-ui/icons'

const styles = theme => ({
  card: {
    flexGrow: 1,
    margin: theme.spacing(1),
    position: 'relative',
    '& .MuiCardActionArea-root': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-between',
      alignItems: 'start',
    },
    '& img': {
      width: '100%',
    },
    '& svg': {
      width: '100%',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  product: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  form: {
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    alignItems: 'end',
    '& button': {
      height: '100%',
      '& svg': {
        width: 'auto',
      },
    },
  },
  closeButton: {
    width: '100px',
    marginRight: '-20px',
    position: 'absolute',
    right: 0,
    top: 0,
    color: 'rgba(150, 150, 150, 0.7)',
  },
  placeholder: {
    height: 48,
  },
  minusButton: {
    background: theme.palette.error.main,
    color: 'white',
  },
  leaveComment: {
    position: 'relative',
    width: '90%',
    margin: '5%',
  },
  input: {
    width: '100%',
  },
})

function OrderDialog(props) {
  const {
    price, open, onClose, classes, status, onChange,
  } = props
  const [comment, setComment] = React.useState('')

  function changeOrderStatus(event) {
    event.preventDefault()

    setComment(event.target.value)
  }

  const stopBubble = event => {
    event.stopPropagation()
  }

  const onSubmit = event => {
    event.preventDefault()
    onChange({
      status,
      comment,
    })

    onClose(event)
  }

  const button = () => {
    if (status === 'acquired') {
      return (
        <Button className={classes.leaveComment} variant="contained" color="secondary" type="submit" onClick={onSubmit}>
          Принять товар
        </Button>
      )
    }
    return (
      <Button className={classes.leaveComment} variant="contained" type="submit" onClick={onSubmit}>
        Вернуть товар
      </Button>
    )
  }

  return (
    <Backdrop open={open} onClick={onClose} className={classes.backdrop}>
      <Card className={classes.card} onClick={stopBubble}>
        <CardContent>
          <form className={classes.form} onSubmit={onSubmit}>
            <Typography variant="body1" component="span">
              Пожалуйста, оставьте комментарий для
            </Typography>
            <TextField
              label="Комментарий"
              value={price.quantity}
              // type="text"
              onChange={changeOrderStatus}
              className={classes.input}
            />
          </form>
        </CardContent>

        {button()}
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Card>
    </Backdrop>
  )
}

OrderDialog.propTypes = {
  price: PropTypes.objectOf(PropTypes.any),
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  status: PropTypes.string,
}

OrderDialog.defaultProps = {
  price: {
    product: {
      name: '',
    },
    unit: {
      name: ' ',
    },
  },
  open: false,
  status: '',
}

export default withStyles(styles)(OrderDialog)
