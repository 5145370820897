import * as Sentry from '@sentry/browser'
import { takeEvery, put, call } from 'redux-saga/effects'
import * as types from './types'
import branchAPI from '../../api/branch'

function* getBranches(action) {
  try {
    const response = yield call(branchAPI.getBranches, action.payload)
    return yield put({
      type: types.GET_BRANCHES_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.GET_BRANCHES_ERROR,
      payload: error.response,
    })
  }
}

export default function* branchSaga() {
  yield takeEvery(types.GET_BRANCHES, getBranches)
}
