import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  List,
  ListItem,
  Typography,
  Divider,
  withStyles,
} from '@material-ui/core'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { orderActions } from '../../redux/order'
import MainOrders from '../Orders/MainOrders'
import { describeStatus } from '../../helpers'

const styles = theme => ({
  root: {
    position: 'fixed',
    bottom: 0,
    top: 56,
    width: '100%',
    overflow: 'scroll',
    maxWidth: 600,
    left: 0,
    right: 0,
    margin: '0 auto',
    background: 'white',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  img: {
    width: '100%',
  },
  float: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'end',
  },
  itemLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  item: {
    flexDirection: 'column',
    alignItems: 'start',
  },
  warning: {
    color: theme.palette.warning.main,
  },
  success: {
    color: theme.palette.warning.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  secondary: {
    color: theme.palette.text.secondary,
  },
  telegram: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '120px',
    width: '100%',
  },
})

class ArchivedOrders extends Component {
  componentDidMount() {
    const { apiSet, getOrders } = this.props

    if (apiSet) {
      getOrders()
    }
  }

  componentDidUpdate(prevProps) {
    const { apiSet, getOrders } = this.props

    if (apiSet && apiSet !== prevProps.apiSet) {
      getOrders()
    }
  }

  render() {
    const { orders, classes } = this.props

    const statusText = status => (
      <Typography variant="body2" className={classes[describeStatus(status).color]}>
        {describeStatus(status).text}
      </Typography>
    )

    const mainStatus = rs => {
      if (rs.every(el => el.status === 'new')) {
        return (
          <Typography variant="body2" className={classes.success}>
            Создана
          </Typography>
        )
      }
      if (rs.every(el => el.status === 'rejected' || el.status === 'changes_rejected' || el.status === 'acquired')) {
        return (
          <Typography variant="body2" className={classes.secondary}>
            Закрыта
          </Typography>
        )
      }

      return (
        <Typography variant="body2" className={classes.warning}>
          В процессе
        </Typography>
      )
    }

    const orderList = (rdrs) => (
      rdrs.map(order => {
        const createDate = new Date(order.createDate)

        return (
          <div key={order.id}>
            <RouterLink to={`/order/${order.id}`} className={classes.itemLink}>
              <ListItem className={classes.item}>
                <Typography variant="body1">
                  {`#${order.number}. ${order.supplier}`}
                </Typography>
                <div className={classes.float}>
                  <Typography variant="body2">
                    {createDate.toLocaleString()}
                  </Typography>
                  <Typography variant="body2">
                    {order.branch}
                  </Typography>
                </div>

                <div className={classes.telegram}>
                  {statusText(order.status)}

                </div>
              </ListItem>
            </RouterLink>
            <Divider />
          </div>
        )
      })
    )

    const mainOrders = orders.filter(order => order.archived === true).map((mainOrder) => (
      <MainOrders
        statusText={mainStatus}
        key={mainOrder.id}
        orderList={orderList}
        classes={classes}
        mainOrder={mainOrder}
      />
    ),
    )

    return (
      <div className={classes.root}>
        <List>
          {mainOrders}
        </List>
      </div>
    )
  }
}

ArchivedOrders.propTypes = {
  apiSet: PropTypes.bool,
  getOrders: PropTypes.func.isRequired,
  orders: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
}

ArchivedOrders.defaultProps = {
  apiSet: false,
  orders: [],
}

const mapStateToProps = state => ({
  apiSet: state.session.apiSet,
  orders: state.order.orders,
})

export default connect(mapStateToProps, {
  getOrders: orderActions.getOrders,
})(withStyles(styles)(ArchivedOrders))
