import * as types from './types'

const initialState = {
  product: {},
}

export default function (state = initialState, action) {
  const { payload } = action

  switch (action.type) {
    case types.GET_PRODUCTS_SUCCESS:
      return {
        products: payload,
        units: state.units,
        groups: state.groups,
      }
    case types.GET_PRODUCTS_ERROR:
      return { ...state, error: payload.status }

    case types.CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        ...payload,
      }
    case types.CREATE_PRODUCT_ERROR:
      return { ...state, error: payload.status }

    case types.UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        ...payload,
      }
    case types.UPDATE_PRODUCT_ERROR:
      return { ...state, error: payload.status }

    case types.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        ...payload,
      }
    case types.GET_PRODUCT_ERROR:
      return { ...state, error: payload.status }

    case types.CHANGE_PRODUCT:
      return {
        ...state,
        [payload.name]: payload.value,
      }

    case types.UPLOAD_PRODUCT_PHOTO_SUCCESS:
      return {
        ...state,
        photo: payload.photo,
      }
    case types.UPLOAD_PRODUCT_PHOTO_ERROR:
      return { ...state, error: payload.status }

    case types.GET_UNITS_SUCCESS:
      return {
        ...state,
        units: payload,
      }
    case types.GET_UNITS_ERROR:
      return { ...state, error: payload.status }

    case types.GET_GROUPS_SUCCESS:
      return {
        ...state,
        groups: payload,
      }
    case types.GET_GROUPS_ERROR:
      return { ...state, error: payload.status }

    default:
      return {
        ...state,
      }
  }
}
