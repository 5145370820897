import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  List, ListItem, ListItemText, Divider, Fab, withStyles,
} from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import { connect } from 'react-redux'
import { Link as RouterLink, withRouter } from 'react-router-dom'
import { supplierActions } from '../../redux/supplier'

const styles = theme => ({
  root: {
    width: '100%',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
})

class Prices extends Component {
  componentDidMount() {
    const { apiSet, getPrices, match } = this.props

    if (apiSet) {
      getPrices({
        company: match.params.id,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { apiSet, getPrices, match } = this.props

    if (apiSet && apiSet !== prevProps.apiSet) {
      getPrices({
        company: match.params.id,
      })
    }
  }

  render() {
    const { prices, classes } = this.props

    const priceList = prices.map(p => (
      <div key={p.id}>
        <ListItem button component={RouterLink} to={`/price/${p.id}`}>
          <ListItemText primary={`${p.product}, ${p.unit} - ${p.value} тг.`} />
        </ListItem>
        <Divider />
      </div>
    ))

    return (
      <div className={classes.root}>
        <List>
          {priceList}
        </List>

        <Fab color="secondary" className={classes.fab} component={RouterLink} to="/new-price">
          <AddIcon />
        </Fab>
      </div>
    )
  }
}

Prices.propTypes = {
  apiSet: PropTypes.bool,
  getPrices: PropTypes.func.isRequired,
  prices: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
}

Prices.defaultProps = {
  apiSet: false,
  prices: [],
}

const mapStateToProps = state => ({
  apiSet: state.session.apiSet,
  prices: state.supplier.prices,
})

export default withRouter(connect(mapStateToProps, {
  getPrices: supplierActions.getPrices,
})(withStyles(styles)(Prices)))
