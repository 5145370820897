import * as types from './types'

const initialState = {
  apiSet: false,
}

export default function (state = initialState, action) {
  const { payload } = action

  switch (action.type) {
    case types.LOGOUT_USER:
      return {}
    case types.LOGIN_USER:
      return { ...state, error: null }
    case types.LOGIN_USER_SUCCESS:
      return { ...state, ...payload }
    case types.LOGIN_USER_ERROR:
      return { ...state, error: payload.status }
    case types.SET_API_AUTH:
      return { ...state, apiSet: payload }
    case types.GET_SESSION:
      return { ...state, payload }
    case types.GET_SESSION_SUCCESS:
      return {
        ...state,
        phone: payload.phone,
        name: payload.name,
        branches: payload.branches,
        role: payload.role,
      }
    case types.GET_SESSION_ERROR:
      return { ...state, error: payload.status }
    case types.SET_PASSWORD:
      return { ...state, error: null }
    case types.SET_PASSWORD_SUCCESS:
      return { ...state, ...payload }
    case types.SET_PASSWORD_ERROR:
      return { ...state, error: payload.status }
    case types.CLEAR_SESSION:
      return {}
    default:
      return { ...state, error: null }
  }
}
