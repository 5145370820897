export const GET_ORDERS = 'GET_ORDERS'
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS'
export const GET_ORDERS_ERROR = 'GET_ORDERS_ERROR'

export const GET_MARKET = 'GET_MARKET'
export const GET_MARKET_SUCCESS = 'GET_MARKET_SUCCESS'
export const GET_MARKET_ERROR = 'GET_MARKET_ERROR'

export const ADD_TO_CART = 'ADD_TO_CART'
export const DELETE_FROM_CART = 'DELETE_FROM_CART'

export const CHANGE_SEARCH = 'CHANGE_SEARCH'

export const SET_MARKET_GROUP = 'SET_MARKET_GROUP'

export const CREATE_ORDERS = 'CREATE_ORDERS'
export const CREATE_ORDERS_SUCCESS = 'CREATE_ORDERS_SUCCESS'
export const CREATE_ORDERS_ERROR = 'CREATE_ORDERS_ERROR'

export const CREATE_ADDITIONAL_ORDER = 'CREATE_ADDITIONAL_ORDER'
export const CREATE_ADDITIONAL_ORDER_SUCCESS = 'CREATE_ADDITIONAL_ORDER_SUCCESS'
export const CREATE_ADDITIONAL_ORDER_ERROR = 'CREATE_ADDITIONAL_ORDER_ERROR'

export const CLEAR_CART = 'CLEAR_CART'

export const GET_ORDER = 'GET_ORDER'
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS'
export const GET_ORDER_ERROR = 'GET_ORDER_ERROR'

export const GET_MAIN_ORDER = 'GET_MAIN_ORDER'
export const GET_MAIN_ORDER_SUCCESS = 'GET_MAIN_ORDER_SUCCESS'
export const GET_MAIN_ORDER_ERROR = 'GET_MAIN_ORDER_ERROR'

export const CHANGE_ORDER = 'CHANGE_ORDER'

export const CHANGE_ORDER_DETAILS = 'CHANGE_ORDER_DETAILS'

export const UPDATE_ORDER = 'UPDATE_ORDER'
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS'
export const UPDATE_ORDER_ERROR = 'UPDATE_ORDER_ERROR'

export const SEND_ORDER = 'SEND_ORDER'
export const SEND_ORDER_SUCCESS = 'SEND_ORDER_SUCCESS'
export const SEND_ORDER_ERROR = 'SEND_ORDER_ERROR'

export const GET_REPORT = 'GET_REPORT'
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS'
export const GET_REPORT_ERROR = 'GET_REPORT_ERROR'

export const SEND_ARCHIVE_ORDER = 'SEND_ARCHIVE_ORDER'
export const SEND_ARCHIVE_ORDER_SUCCESS = 'SEND_ARCHIVE_ORDER_SUCCESS'
export const SEND_ARCHIVE_ORDER_ERROR = 'SEND_ARCHIVE_ORDER_ERROR'

export const NOTIFY_SUPPLIER_STATUS = 'NOTIFY_SUPPLIER_STATUS'
export const NOTIFY_SUPPLIER_STATUS_SUCCESS = 'NOTIFY_SUPPLIER_STATUS_SUCCESS'
export const NOTIFY_SUPPLIER_STATUS_ERROR = 'NOTIFY_SUPPLIER_STATUS_ERROR'

export const GET_ORDER_STATUS_SUCCESS = 'GET_ORDER_STATUS_SUCCESS'
export const GET_ORDER_STATUS_ERROR = 'GET_ORDER_STATUS_ERROR'

export const SET_OPENED_MAIN_ORDERS = 'SET_OPENED_MAIN_ORDERS'
export const SET_OPENED_MAIN_ORDERS_SUCCESS = 'SET_OPENED_MAIN_ORDERS_SUCCESS'
export const SET_OPENED_MAIN_ORDERS_ERROR = 'SET_OPENED_MAIN_ORDERS_ERROR'
