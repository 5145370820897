import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Fab,
  withStyles,
  TextField,
  FormControl,
  Button,
  InputLabel,
  Select,
} from '@material-ui/core'
import { Check as CheckIcon } from '@material-ui/icons'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { productActions } from '../../redux/product'
import { photosHost } from '../../local'

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    width: '100%',
    margin: '60px auto',
    maxWidth: 600,
    background: 'white',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  form: {
    '& .MuiFormControl-root': {
      width: '100%',
      marginBottom: theme.spacing(3),
    },
    '& .MuiButton-root': {
      marginTop: theme.spacing(3),
    },
  },
  fileInput: {
    display: 'none',
  },
  img: {
    width: '100%',
  },
})

class Product extends Component {
  constructor(props) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
    this.formValid = this.formValid.bind(this)
    this.uploadPhoto = this.uploadPhoto.bind(this)
  }

  componentDidMount() {
    const {
      id,
      history,
      location,
      apiSet,
      get,
      match,
      getUnits,
    } = this.props

    if (id && location.pathname === '/new-product') {
      history.replace(`/product/${id}`)
    }

    if (apiSet) {
      getUnits()

      if (match.params.id && !id) {
        get({ id: match.params.id })
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      id,
      history,
      location,
      get,
      match,
      apiSet,
      getUnits,
    } = this.props

    if (id && location.pathname === '/new-product') {
      history.replace(`/product/${id}`)
    }

    if (apiSet && apiSet !== prevProps.apiSet) {
      getUnits()

      if (match.params.id && !id) {
        get({ id: match.params.id })
      }
    }
  }

  onSubmit(event) {
    event.preventDefault()
    const {
      create, update, location, id, name, unit, group,
    } = this.props

    const product = {
      id, name, unit, group,
    }

    if (location.pathname === '/new-product') {
      return create(product)
    }

    return update(product)
  }

  formValid() {
    const { name, unit, group } = this.props

    if (!name) return false
    if (!unit) return false
    if (!group) return false

    return true
  }

  uploadPhoto(event) {
    const { upload, id } = this.props

    event.preventDefault()

    const data = new FormData()
    data.append('file', event.target.files[0])
    data.append('id', id)

    upload(data)
  }

  render() {
    const {
      classes, change, name, photo, unit, units, group, groups, location,
    } = this.props

    const isError = _field => {
      let helperText

      // if (field === 'phone' && error === 404) {
      //   helperText = 'Номер не найден'
      // }

      // if (field === 'password' && error === 403) {
      //   helperText = 'Неверный пароль'
      // }

      return helperText
    }

    const onFieldChange = fieldName => event => {
      change({
        name: fieldName,
        value: event.target.value,
      })
    }

    const photoInput = location.pathname === '/new-product' ? null : (
      <FormControl>
        <label>Фотография</label>

        <img src={`${photosHost}/${photo}`} alt="Фото" className={classes.img} />

        <label htmlFor="image-file">
          <Button variant="contained" component="span">
            Загрузить
          </Button>
        </label>

        <input
          accept="image/*"
          className={classes.fileInput}
          id="image-file"
          type="file"
          onChange={this.uploadPhoto}
        />
      </FormControl>
    )

    return (
      <div className={classes.root}>
        <form onSubmit={this.onSubmit} className={classes.form}>
          <TextField error={!!isError('name')} label="Наименование" value={name} onChange={onFieldChange('name')} helperText={isError('name')} />

          {photoInput}

          <FormControl>
            <InputLabel htmlFor="unit">Единица измерения</InputLabel>
            <Select
              native
              value={unit}
              onChange={onFieldChange('unit')}
              inputProps={{
                name: 'unit',
                id: 'unit',
              }}
            >
              <option value=""> </option>
              {units.map(u => <option value={u.id} key={u.id}>{u.name}</option>)}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel htmlFor="group">Товарная группа</InputLabel>
            <Select
              native
              value={group}
              onChange={onFieldChange('group')}
              inputProps={{
                name: 'group',
                id: 'group',
              }}
            >
              <option value=""> </option>
              {groups.map(g => <option value={g.id} key={g.id}>{g.name}</option>)}
            </Select>
          </FormControl>

          <Fab color="secondary" className={classes.fab} disabled={!this.formValid()} type="submit">
            <CheckIcon />
          </Fab>
        </form>
      </div>
    )
  }
}

Product.propTypes = {
  apiSet: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  change: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  upload: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  photo: PropTypes.string,
  getUnits: PropTypes.func.isRequired,
  unit: PropTypes.string,
  units: PropTypes.arrayOf(PropTypes.object),
  group: PropTypes.string,
  groups: PropTypes.arrayOf(PropTypes.object),
}

Product.defaultProps = {
  apiSet: false,
  id: null,
  name: '',
  photo: null,
  unit: '',
  units: [],
  group: '',
  groups: [],
}

const mapStateToProps = state => ({
  apiSet: state.session.apiSet,
  id: state.product.id,
  name: state.product.name,
  photo: state.product.photo,
  unit: state.product.unit,
  units: state.product.units,
  group: state.product.group,
  groups: state.product.groups,
})

export default withRouter(connect(mapStateToProps, {
  get: productActions.get,
  change: productActions.change,
  create: productActions.create,
  update: productActions.update,
  upload: productActions.upload,
  getUnits: productActions.getUnits,
  getGroups: productActions.getGroups,
})(withStyles(styles)(Product)))
