import * as types from './types'

export const login = (payload) => ({
  type: types.LOGIN_USER,
  payload,
})

export const logout = (payload) => ({
  type: types.LOGOUT_USER,
  payload,
})

export const setAPIAuth = payload => ({
  type: types.SET_API_AUTH,
  payload,
})

export const get = payload => ({
  type: types.GET_SESSION,
  payload,
})

export const setPassword = payload => ({
  type: types.SET_PASSWORD,
  payload,
})

export const clear = payload => ({
  type: types.CLEAR_SESSION,
  payload,
})
