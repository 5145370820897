import { createStore, applyMiddleware } from 'redux'
import throttle from 'lodash/throttle'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import reducers from './reducers'
import sagas from './sagas'
import { env } from '../local'

const sagaMiddleware = createSagaMiddleware()
let middleware

if (env === 'dev') {
  middleware = composeWithDevTools(
    applyMiddleware(sagaMiddleware),
  )
} else {
  middleware = applyMiddleware(sagaMiddleware)
}

const initialState = localStorage.getItem('redux_state') ? JSON.parse(localStorage.getItem('redux_state')) : {}

if (initialState.session) {
  initialState.session.apiSet = false
}

if (initialState.notification) {
  initialState.notification.message = ''
}

if (initialState.user) {
  initialState.user.user = {}
}

if (initialState.supplier) {
  initialState.supplier.member = {}
  initialState.supplier.price = {}
}

if (initialState.order) {
  initialState.order.market = []
  initialState.order.report = []
  initialState.order.openedOrders = []

  if (!initialState.order.cart) {
    initialState.order.cart = []
  }
}

const store = createStore(reducers, initialState, middleware)

sagas.forEach(saga => sagaMiddleware.run(saga))

store.subscribe(throttle(() => localStorage.setItem('redux_state', JSON.stringify(store.getState())), 100))

export default store
