import api from './api'

const userAPI = {
  login: data => api.post(`/login`, data),
  get: params => api.get(`/user`, {
    params,
  }),
  getSession: params => api.get(`/session`, {
    params,
  }),
  getUsers: params => api.get(`/users`, {
    params,
  }),
  create: data => api.post(`/user`, data),
  update: data => api.put(`/user`, data),
  archive: data => api.post('/archive', data),
  setPassword: data => api.post(`/set-password`, data),
}

export default userAPI
