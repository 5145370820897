import * as Sentry from '@sentry/browser'
import { takeEvery, put, call } from 'redux-saga/effects'
import * as types from './types'
import cityAPI from '../../api/city'

function* getCities(action) {
  try {
    const response = yield call(cityAPI.getCities, action.payload)
    return yield put({
      type: types.GET_CITIES_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.GET_CITIES_ERROR,
      payload: error.response,
    })
  }
}

export default function* citySaga() {
  yield takeEvery(types.GET_CITIES, getCities)
}
