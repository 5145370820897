import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  List, ListItem, ListItemText, Divider, Fab, withStyles,
} from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import { connect } from 'react-redux'
import { Link as RouterLink, withRouter } from 'react-router-dom'
import { supplierActions } from '../../redux/supplier'
import { formatPhone } from '../../helpers'

const styles = theme => ({
  root: {
    width: '100%',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
})

class Members extends Component {
  componentDidMount() {
    const { apiSet, getMembers, match } = this.props

    if (apiSet) {
      getMembers({
        company: match.params.id,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { apiSet, getMembers, match } = this.props

    if (apiSet && apiSet !== prevProps.apiSet) {
      getMembers({
        company: match.params.id,
      })
    }
  }

  render() {
    let { members, classes } = this.props
    members = members.filter(m => !m.isDisabled)
    const memberList = members.map(m => (
      <div key={m.id}>
        <ListItem button component={RouterLink} to={`/member/${m.id}`}>
          <ListItemText primary={`${m.name}`} secondary={`+7 ${formatPhone(m.phone)}`} />
        </ListItem>
        <Divider />
      </div>
    ))

    return (
      <div className={classes.root}>
        <List>
          {memberList}
        </List>

        <Fab color="secondary" className={classes.fab} component={RouterLink} to="/new-member">
          <AddIcon />
        </Fab>
      </div>
    )
  }
}

Members.propTypes = {
  apiSet: PropTypes.bool,
  getMembers: PropTypes.func.isRequired,
  members: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
}

Members.defaultProps = {
  apiSet: false,
  members: [],
}

const mapStateToProps = state => ({
  apiSet: state.session.apiSet,
  members: state.supplier.members,
})

export default withRouter(connect(mapStateToProps, {
  getMembers: supplierActions.getMembers,
})(withStyles(styles)(Members)))
