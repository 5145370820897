import { takeEvery, put, call } from 'redux-saga/effects'
import * as Sentry from '@sentry/browser'
import * as types from './types'
import notificationAPI from '../../api/notification'

function* getNotifications(action) {
  try {
    const response = yield call(notificationAPI.getNotifications, action.payload)
    return yield put({
      type: types.GET_NOTIFICATIONS_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.GET_NOTIFICATIONS_ERROR,
      payload: error.response,
    })
  }
}

export default function* notificationSaga() {
  yield takeEvery(types.GET_NOTIFICATIONS, getNotifications)
}
