import * as types from './types'

export const getOrders = (payload) => ({
  type: types.GET_ORDERS,
  payload,
})

export const getMarket = (payload) => ({
  type: types.GET_MARKET,
  payload,
})

export const addToCart = (payload) => ({
  type: types.ADD_TO_CART,
  payload,
})

export const deleteFromCart = (payload) => ({
  type: types.DELETE_FROM_CART,
  payload,
})

export const changeSearch = (payload) => ({
  type: types.CHANGE_SEARCH,
  payload,
})

export const setGroup = (payload) => ({
  type: types.SET_MARKET_GROUP,
  payload,
})

export const createOrders = (payload) => ({
  type: types.CREATE_ORDERS,
  payload,
})

export const createAdditionalOrder = (payload) => ({
  type: types.CREATE_ADDITIONAL_ORDER,
  payload,
})

export const clearCart = (payload) => ({
  type: types.CLEAR_CART,
  payload,
})

export const get = (payload) => ({
  type: types.GET_ORDER,
  payload,
})

export const getMainOrder = (payload) => ({
  type: types.GET_MAIN_ORDER,
  payload,
})

export const change = (payload) => ({
  type: types.CHANGE_ORDER,
  payload,
})

export const changeDetails = (payload) => ({
  type: types.CHANGE_ORDER_DETAILS,
  payload,
})

export const update = (payload) => ({
  type: types.UPDATE_ORDER,
  payload,
})

export const send = (payload) => ({
  type: types.SEND_ORDER,
  payload,
})

export const getReport = (payload) => ({
  type: types.GET_REPORT,
  payload,
})

export const sendToArchive = (payload) => ({
  type: types.SEND_ARCHIVE_ORDER,
  payload,
})

export const notifySupplierStatus = (payload) => ({
  type: types.NOTIFY_SUPPLIER_STATUS,
  payload,
})

export const setOpenedMainOrders = (payload) => ({
  type: types.SET_OPENED_MAIN_ORDERS,
  payload,
})
