import React from 'react'
import {
  AppBar,
  Toolbar,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  withStyles,
  Badge,
  InputBase,
  InputAdornment,
} from '@material-ui/core'
import {
  Menu as MenuIcon,
  ArrowBack as ArrowBackIcon,
  ShoppingCartOutlined as CartIcon,
  Search as SearchIcon,
  Close as CloseIcon,
} from '@material-ui/icons'
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { orderActions } from '../redux/order'
import { sessionActions } from '../redux/session'

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

const styles = _theme => ({
  list: {
    width: 200,
  },
  title: {
    flexGrow: 1,
  },
  search: {
    color: 'inherit',
    flexGrow: 1,
  },
  appBar: {
    maxWidth: 600,
    margin: '0 auto',
    left: 0,
  },
})

class Navbar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      searchOpen: false,
    }

    this.toggleDrawer = this.toggleDrawer.bind(this)
    this.toggleSearch = this.toggleSearch.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onLogout = this.onLogout.bind(this)
  }

  onChange(event) {
    const { changeSearch } = this.props

    event.preventDefault()

    changeSearch(event.target.value)
  }

  onLogout() {
    const { clear } = this.props

    clear()
    this.toggleDrawer()
  }

  toggleDrawer() {
    const { open } = this.state

    this.setState({ open: !open })
  }

  toggleSearch() {
    const { changeSearch } = this.props
    const { searchOpen } = this.state

    if (searchOpen) {
      changeSearch('')
    }

    this.setState({ searchOpen: !searchOpen })
  }

  render() {
    const {
      location,
      user,
      history,
      supplier,
      classes,
      member,
      product,
      price,
      cart,
      searchText,
      number,
      role,
    } = this.props
    // console.log(this.props)
    const { open, searchOpen } = this.state
    let title

    const slashPos = location.pathname.indexOf('/', 1)
    const path = slashPos > 0 ? location.pathname.substring(0, slashPos) : location.pathname

    let e = {
      product: 'Product',
      unit: 'unit',
      value: 0
    }
    function info(id) {
      return supplier?.prices?.filter(item => {
        if (item.id === id) {
          e = item
        }
        return e
      })
    }
    info(price.id)

    const BackButton = (
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={history.goBack}>
        <ArrowBackIcon />
      </IconButton>
    )

    const BackButtonReplace = (
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => history.replace({ pathname: '/orders' })}>
        <ArrowBackIcon />
      </IconButton>
    )

    const MenuButton = (
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={this.toggleDrawer}>
        <MenuIcon />
      </IconButton>
    )

    const CartButton = (
      <IconButton color="inherit" component={Link} to="/cart">
        <Badge badgeContent={cart.length} color="error">
          <CartIcon />
        </Badge>
      </IconButton>
    )

    const SearchButton = (
      <IconButton color="inherit" onClick={this.toggleSearch}>
        <SearchIcon />
      </IconButton>
    )

    let LeftButton
    let RightButton
    let MiddleButton
    switch (path) {
      case '/login':
        title = 'Вход в систему'
        break
      case '/set-password':
        title = 'Создание пароля'
        break
      case '/users':
        title = 'Пользователи'
        LeftButton = MenuButton
        break
      case '/new-user':
        title = 'Новый пользователь'
        LeftButton = BackButton

        break
      case `/user`:
        title = user.name
        LeftButton = BackButton

        break
      case '/suppliers':
        title = 'Поставщики'
        LeftButton = MenuButton
        break
      case '/new-supplier':
        title = 'Новый поставщик'
        LeftButton = BackButton

        break
      case `/supplier`:
        title = supplier.name
        LeftButton = BackButton

        break

      case '/new-member':
        title = 'Новый представитель'
        LeftButton = BackButton

        break
      case `/member`:
        title = member.name
        LeftButton = BackButton

        break

      case '/new-price':
        title = 'Новый прайс'
        LeftButton = BackButton

        break
      case `/price`:
        title = `${e.product}, ${e.unit} - ${e.value}`
        LeftButton = BackButton

        break

      case '/products':
        title = 'Товары'
        LeftButton = MenuButton
        break

      case '/new-product':
        title = 'Новый товар'
        LeftButton = BackButton

        break
      case `/product`:
        title = product.name
        LeftButton = BackButton

        break

      case `/market`:
        title = 'Супермаркет'
        LeftButton = BackButton
        RightButton = CartButton
        MiddleButton = SearchButton

        break

      case '/orders':
        title = 'Заявки'
        LeftButton = MenuButton
        break

      case '/notifications':
        title = 'Уведомления'
        LeftButton = MenuButton
        break

      case '/archive':
        title = 'Архив заявок'
        LeftButton = MenuButton
        break

      case `/cart`:
        title = 'Корзина'
        LeftButton = BackButton

        break

      case `/order`:
        title = `Заявка #${number}`
        LeftButton = history.location.key ? BackButton : BackButtonReplace

        break

      case `/main-order`:
        title = `Заявка #${number} на cнабжение`
        LeftButton = BackButton

        break

      case `/archives`:
        title = `Заявка #${number}`
        LeftButton = BackButton

        break

      case '/report':
        title = 'Отчет по товарам и поставщикам'
        LeftButton = MenuButton
        break

      default:
        title = 'Chechil Закупки'
        break
    }

    const Menu = searchOpen ? (
      <Toolbar>
        <InputBase
          placeholder="Поиск ..."
          className={classes.search}
          startAdornment={(
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton color="inherit" onClick={this.toggleSearch}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          )}
          autoFocus
          value={searchText}
          onChange={this.onChange}
        />
      </Toolbar>
    ) : (
      <Toolbar>
        {LeftButton}

        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>

        {MiddleButton}
        {RightButton}
      </Toolbar>
    )

    const adminItems = role === 'admin' ? (
      <div>
        <ListItem button component={Link} to="/suppliers" onClick={this.toggleDrawer}>
          <ListItemText primary="Поставщики" />
        </ListItem>

        <ListItem button component={Link} to="/products" onClick={this.toggleDrawer}>
          <ListItemText primary="Товары" />
        </ListItem>

        <ListItem button component={Link} to="/users" onClick={this.toggleDrawer}>
          <ListItemText primary="Пользователи" />
        </ListItem>

        <ListItem button component={Link} to="/report" onClick={this.toggleDrawer}>
          <ListItemText primary="Отчет" />
        </ListItem>

        <ListItem button component={Link} to="/notifications" onClick={this.toggleDrawer}>
          <ListItemText primary="Уведомления" />
        </ListItem>
      </div>
    ) : null

    return (
      <div>
        <AppBar position="fixed" className={classes.appBar}>
          {Menu}
        </AppBar>

        <SwipeableDrawer
          open={open}
          onClose={this.toggleDrawer}
          onOpen={this.toggleDrawer}
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          disableSwipeToOpen
        >
          <List className={classes.list}>
            <ListItem button component={Link} to="/orders" onClick={this.toggleDrawer}>
              <ListItemText primary="Заявки" />
            </ListItem>

            <ListItem button component={Link} to="/archive" onClick={this.toggleDrawer}>
              <ListItemText primary="Архив заявок" />
            </ListItem>

            {adminItems}

            <ListItem button onClick={this.onLogout}>
              <ListItemText primary="Выход" />
            </ListItem>
          </List>
        </SwipeableDrawer>
      </div>

    )
  }
}

Navbar.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  supplier: PropTypes.objectOf(PropTypes.any),
  product: PropTypes.objectOf(PropTypes.any),
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  member: PropTypes.objectOf(PropTypes.any),
  price: PropTypes.objectOf(PropTypes.any),
  cart: PropTypes.arrayOf(PropTypes.object),
  searchText: PropTypes.string,
  changeSearch: PropTypes.func.isRequired,
  number: PropTypes.number,
  role: PropTypes.string,
  clear: PropTypes.func.isRequired,
}

Navbar.defaultProps = {
  user: {},
  supplier: {},
  member: {},
  product: {},
  price: {},
  cart: [],
  searchText: '',
  number: null,
  role: '',
}

const mapStateToProps = state => ({
  user: state.user.user,
  supplier: { ...state.supplier, suppliers: undefined },
  member: state.supplier.member,
  product: state.product,
  price: state.supplier.price,
  cart: state.order.cart,
  searchText: state.order.searchText,
  number: state.order.number,
  role: state.session.role,
})

export default connect(mapStateToProps, {
  changeSearch: orderActions.changeSearch,
  clear: sessionActions.clear,
})(withRouter(withStyles(styles)(Navbar)))
