import React from 'react'
import {
  Paper, makeStyles, Typography,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(5, 3),
    position: 'fixed',
    top: 56,
    bottom: 0,
    width: '100%',
  },
  form: {
    '& .MuiTextField-root': {
      width: '100%',
      marginBottom: theme.spacing(3),
    },
    '& .MuiButton-root': {
      marginTop: theme.spacing(3),
    },
  },
}))

function NotFound() {
  const classes = useStyles()

  return (
    <Paper elevation={0} className={classes.paper}>
      <Typography variant="h5">Страница не найдена.</Typography>
    </Paper>
  )
}

export default NotFound
