import api from './api'

const supplierAPI = {
  getSuppliers: params => api.get(`/suppliers`, {
    params,
  }),
  create: data => api.post(`/supplier`, data),
  get: params => api.get(`/supplier`, {
    params,
  }),
  update: data => api.put(`/supplier`, data),
  getMembers: params => api.get(`/supplier-members`, {
    params,
  }),
  createMember: data => api.post(`/supplier-member`, data),
  getMember: params => api.get(`/supplier-member`, {
    params,
  }),
  updateMember: data => api.put(`/supplier-member`, data),
  getPrices: params => api.get(`/prices`, {
    params,
  }),
  createPrice: data => api.post(`/price`, data),
  getPrice: params => api.get(`/price`, {
    params,
  }),
  updatePrice: data => api.put(`/price`, data),
}

export default supplierAPI
