import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  withStyles,
  Typography,
} from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter, useLocation } from 'react-router-dom'
import { orderActions } from '../../redux/order'
import api from '../../api/api'
import { sessionActions } from '../../redux/session'
import { describeStatus } from '../../helpers'

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    width: '100%',
    margin: '60px auto',
    maxWidth: 600,
    background: 'white',
  },
  form: {
    '& .MuiFormControl-root': {
      width: '100%',
      marginBottom: theme.spacing(3),
    },
    '& .MuiButton-root': {
      marginTop: theme.spacing(3),
    },
  },
  float: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
})

function MainOrder({
  apiSet,
  classes,
  details,
  getMainOrder,
  getUser,
  match,
  setAPIAuth,
  status,
}) {

  const { search } = useLocation()
  let c = ''
  if (search) {
    c = search.slice(3)
  }

  useEffect(() => {
      if (apiSet) {
        getMainOrder({ id: match.params.id })
      } else if (c) {
        api.defaults.headers.common.Authorization = c
        setAPIAuth(true)

        getUser()

        getMainOrder({ id: match.params.id })
      }
    }, [apiSet, getMainOrder, match.params.id, c, setAPIAuth, getUser])

  const detailList = details.map((d, i) => (
    <div className={classes.float} key={i}>
      <Typography variant="body2">
        { d.product.name }
      </Typography>

      <Typography variant="body1">
        { `${d.quantity} ${d.unit.name}` }
      </Typography>
    </div>
  ))

  return (
    <div className={classes.root}>
      <form className={classes.form}>
        <div className={classes.float}>
          <Typography variant="body1">
            Статус
          </Typography>

          <Typography variant="body1">
            { describeStatus(status).text }
          </Typography>
        </div>
        <Typography variant="body1" className={classes.title}>
          Продукты
        </Typography>
        { detailList }
      </form>
    </div>
  )
}

MainOrder.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  setAPIAuth: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  status: PropTypes.string,
  details: PropTypes.arrayOf(PropTypes.any),
  apiSet: PropTypes.bool,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  getMainOrder: PropTypes.func.isRequired,
}

MainOrder.defaultProps = {
  apiSet: false,
  status: '',
  details: [],
}

const mapStateToProps = state => ({
  apiSet: state.session.apiSet,
  user: state.order.user,
  branch: state.order.branch,
  status: state.order.status,
  details: state.order.details,
  role: state.session.role,
  original: state.order.original,
})

export default withRouter(connect(mapStateToProps, {
  setAPIAuth: sessionActions.setAPIAuth,
  getUser: sessionActions.get,
  getMainOrder: orderActions.getMainOrder,
})(withStyles(styles)(MainOrder)))
