import * as Sentry from '@sentry/browser'
import { takeEvery, put, call, takeLatest } from 'redux-saga/effects'
import * as types from './types'
import productAPI from '../../api/product'
import * as notificationTypes from '../notification/types'

function* getProducts(action) {
  try {
    const response = yield call(productAPI.getProducts, action.payload)
    return yield put({
      type: types.GET_PRODUCTS_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.GET_PRODUCTS_ERROR,
      payload: error.response,
    })
  }
}

function* create(action) {
  try {
    const response = yield call(productAPI.create, action.payload)

    yield put({
      type: types.CREATE_PRODUCT_SUCCESS,
      payload: response.data,
    })

    return yield put({
      type: notificationTypes.NOTIFY,
      payload: 'Товар создан.',
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.CREATE_PRODUCT_ERROR,
      payload: error.response,
    })
  }
}

function* update(action) {
  try {
    const response = yield call(productAPI.update, action.payload)

    yield put({
      type: types.UPDATE_PRODUCT_SUCCESS,
      payload: response.data,
    })

    return yield put({
      type: notificationTypes.NOTIFY,
      payload: 'Товар сохранен.',
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.UPDATE_PRODUCT_ERROR,
      payload: error.response,
    })
  }
}

function* get(action) {
  try {
    const response = yield call(productAPI.get, action.payload)
    return yield put({
      type: types.GET_PRODUCT_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.GET_PRODUCT_ERROR,
      payload: error.response,
    })
  }
}

function* upload(action) {
  try {
    const response = yield call(productAPI.upload, action.payload)
    yield put({
      type: types.UPLOAD_PRODUCT_PHOTO_SUCCESS,
      payload: response.data,
    })

    return yield put({
      type: notificationTypes.NOTIFY,
      payload: 'Фотография загружена.',
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.UPLOAD_PRODUCT_PHOTO_ERROR,
      payload: error.response,
    })
  }
}

function* getUnits(action) {
  try {
    const response = yield call(productAPI.getUnits, action.payload)
    return yield put({
      type: types.GET_UNITS_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.GET_UNITS_ERROR,
      payload: error.response,
    })
  }
}

function* getGroups(action) {
  try {
    const response = yield call(productAPI.getGroups, action.payload)
    return yield put({
      type: types.GET_GROUPS_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    if (error.response.status === 404) {
      return null
    }

    Sentry.captureException(error)

    return yield put({
      type: types.GET_GROUPS_ERROR,
      payload: error.response,
    })
  }
}

export default function* productSaga() {
  yield takeEvery(types.GET_PRODUCTS, getProducts)
  yield takeLatest(types.CREATE_PRODUCT, create)
  yield takeLatest(types.UPDATE_PRODUCT, update)
  yield takeEvery(types.GET_PRODUCT, get)
  yield takeLatest(types.UPLOAD_PRODUCT_PHOTO, upload)
  yield takeEvery(types.GET_UNITS, getUnits)
  yield takeEvery(types.GET_GROUPS, getGroups)
}
