import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  Input,
  InputAdornment,
  IconButton,
  Fab,
  withStyles,
} from '@material-ui/core'
import {
  Check as CheckIcon,
  RemoveCircleOutline as RemoveIcon,
  AddCircleOutline as AddIcon,
} from '@material-ui/icons'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { supplierActions } from '../../redux/supplier'
import PhoneInput from '../../components/PhoneInput'
import { cityActions } from '../../redux/city'

const isError = _field => {
  let helperText

  // if (field === 'phone' && error === 404) {
  //   helperText = 'Номер не найден'
  // }

  // if (field === 'password' && error === 403) {
  //   helperText = 'Неверный пароль'
  // }

  return helperText
}

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    width: '100%',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  form: {
    '& .MuiFormControl-root': {
      width: '100%',
      marginBottom: theme.spacing(3),
    },
    '& .MuiButton-root': {
      marginTop: theme.spacing(3),
    },
  },
  note: {
    marginBottom: '50px !important',
  },
})

class Card extends Component {
  constructor(props) {
    super(props)

    this.state = {
      phone: '',
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onPhoneChange = this.onPhoneChange.bind(this)
    this.formValid = this.formValid.bind(this)
    this.addPhone = this.addPhone.bind(this)
    this.removePhone = this.removePhone.bind(this)
  }

  componentDidMount() {
    const {
      id, apiSet, match, getCities, get,
    } = this.props

    if (apiSet) {
      getCities()

      if (match.params.id && !id) {
        get({ id: match.params.id })
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      apiSet, getCities, match, id, get,
    } = this.props

    if (apiSet && apiSet !== prevProps.apiSet) {
      getCities()

      if (match.params.id && !id) {
        get({ id: match.params.id })
      }
    }
  }

  onSubmit(event) {
    event.preventDefault()
    const {
      create,
      update,
      location,
      id,
      name,
      priority,
      city,
      address,
      bin,
      account,
      note,
      phones,
    } = this.props

    const supplier = {
      id, name, priority, city, address, bin, account, note, phones,
    }

    if (location.pathname === '/new-supplier') {
      return create(supplier)
    }

    return update(supplier)
  }

  onPhoneChange(event) {
    const { phones, change } = this.props

    if (!event.target.value) return true

    if (event.target.name === 'phone') {
      return this.setState({ phone: event.target.value })
    }

    const updatedPhones = phones.map((ph, idx) => {
      const uIdx = Number(event.target.name.substring(1))

      return idx === uIdx ? event.target.value : ph
    })

    return change({
      name: 'phones',
      value: updatedPhones,
    })
  }

  formValid() {
    const { name, city } = this.props

    if (!name) return false
    if (!city) return false

    return true
  }

  addPhone() {
    const { phones, change } = this.props
    const { phone } = this.state

    if (!phone) return

    const newPhones = phones ? [...phones, phone] : [phone]

    change({
      name: 'phones',
      value: newPhones,
    })

    this.setState({ phone: '' })
  }

  removePhone(idx) {
    const { phones, change } = this.props

    change({
      name: 'phones',
      value: phones.filter((_, pIdx) => idx !== pIdx),
    })
  }

  render() {
    const {
      classes,
      name,
      priority,
      change,
      city,
      cities,
      address,
      phones,
      bin,
      account,
      note,
    } = this.props
    const { phone } = this.state

    const onFieldChange = fieldName => event => {
      if(fieldName !== 'priority' || (fieldName === 'priority' && event.target.value >= 0)) {
        change({
          name: fieldName,
          value: event.target.value,
        })
      }
    }

    const phoneList = phones ? phones.map((p, idx) => (
      <FormControl key={p}>
        <InputLabel htmlFor={`p${idx}`}>Телефон</InputLabel>
        <Input
          name={`p${idx}`}
          value={p}
          onChange={this.onPhoneChange}
          startAdornment={<InputAdornment position="start">+7</InputAdornment>}
          inputComponent={PhoneInput}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                onClick={() => this.removePhone(idx)}
              >
                <RemoveIcon />
              </IconButton>
            </InputAdornment>
          )}
        />
      </FormControl>
    )) : []

    return (
      <div className={`${classes.root}`}>

        <form onSubmit={this.onSubmit} className={classes.form}>
          <TextField error={!!isError('name')} label="Наименование" value={name} onChange={onFieldChange('name')} helperText={isError('name')} />

          <TextField error={!!isError('priority')} label="Приоритет" value={priority} onChange={onFieldChange('priority')} helperText={isError('priority')} type="number" />

          <FormControl>
            <InputLabel htmlFor="city">Город</InputLabel>
            <Select
              native
              value={city}
              onChange={onFieldChange('city')}
              inputProps={{
                name: 'city',
                id: 'city',
              }}
            >
              <option value=""> </option>
              {cities.map(c => <option value={c.id} key={c.id}>{c.name}</option>)}
            </Select>
          </FormControl>

          <TextField error={!!isError('address')} label="Адрес" value={address} onChange={onFieldChange('address')} helperText={isError('address')} />

          {phoneList}

          <FormControl>
            <InputLabel htmlFor="phone">Телефон</InputLabel>
            <Input
              name="phone"
              value={phone}
              onChange={this.onPhoneChange}
              startAdornment={<InputAdornment position="start">+7</InputAdornment>}
              inputComponent={PhoneInput}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    onClick={this.addPhone}
                  >
                    <AddIcon />
                  </IconButton>
                </InputAdornment>
              )}
            />
          </FormControl>

          <TextField error={!!isError('bin')} label="БИН" value={bin} onChange={onFieldChange('bin')} helperText={isError('bin')} />
          <TextField error={!!isError('account')} label="Расчетный счет" value={account} onChange={onFieldChange('account')} helperText={isError('account')} />
          <TextField error={!!isError('note')} label="Примечание" value={note} onChange={onFieldChange('note')} helperText={isError('note')} multiline className={classes.note} />

          <Fab color="secondary" className={classes.fab} disabled={!this.formValid()} type="submit">
            <CheckIcon />
          </Fab>
        </form>
      </div>
    )
  }
}

Card.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string,
  address: PropTypes.string,
  bin: PropTypes.string,
  account: PropTypes.string,
  note: PropTypes.string,
  priority: PropTypes.number,
  change: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  id: PropTypes.string,
  phones: PropTypes.arrayOf(PropTypes.string),
  cities: PropTypes.arrayOf(PropTypes.any),
  city: PropTypes.string,
  getCities: PropTypes.func.isRequired,
  apiSet: PropTypes.bool,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
}

Card.defaultProps = {
  id: null,
  name: '',
  address: '',
  bin: '',
  account: '',
  note: '',
  priority: 0,
  city: '',
  cities: [],
  phones: [],
  apiSet: false,
}

const mapStateToProps = state => ({
  apiSet: state.session.apiSet,
  id: state.supplier.id,
  name: state.supplier.name,
  priority: state.supplier.priority,
  city: state.supplier.city,
  cities: state.city.cities,
  address: state.supplier.address,
  bin: state.supplier.bin,
  account: state.supplier.account,
  note: state.supplier.note,
  phones: state.supplier.phones,
})

export default withRouter(connect(mapStateToProps, {
  get: supplierActions.get,
  change: supplierActions.change,
  create: supplierActions.create,
  update: supplierActions.update,
  getCities: cityActions.getCities,
})(withStyles(styles)(Card)))
