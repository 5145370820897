import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ClearIcon from '@material-ui/icons/Clear';
import {
  List,
  ListItem,
  Divider,
  withStyles,
  TextField,
  Typography,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  Button,
} from '@material-ui/core'
import { connect } from 'react-redux'
import { orderActions } from '../../redux/order'

const styles = theme => ({
  root: {
    width: '100%',
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(7),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  img: {
    width: '100%',
  },
  item: {
    flexDirection: 'column',
    alignItems: 'start',
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  input: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  float: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'end',
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  total: {
    textAlign: 'right',
    marginRight: theme.spacing(2),
  },
  close: {
    position: 'absolute',
    top: '-9px',
    right: '5px'
  }
})

class Cart extends Component {
  constructor(props) {
    super(props)

    this.onCreateOrders = this.onCreateOrders.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { ordersCreated, history, clearCart } = this.props

    if (ordersCreated && ordersCreated !== prevProps.ordersCreated) {
      history.go(-2)
      clearCart()
    }
  }

  onDeleteItem(item, event) {
    const { deleteFromCart } = this.props

    deleteFromCart({
      item
    })
  }

  onCreateOrders() {
    const { cart, branches, createOrders } = this.props

    const orders = cart.reduce((acc, item) => {
      const existingOrder = acc.find(order => order.supplier === item.company.id)

      if (existingOrder) {
        return acc.map(order => {
          if (order.supplier === existingOrder.supplier) {
            return {
              ...existingOrder,
              cart: [
                ...existingOrder.cart,
                {
                  product: item.product.id,
                  unit: item.unit.id,
                  quantity: item.quantity,
                  value: item.value,
                },
              ],
            }
          }
          return order
        })
      }

      return [
        ...acc,
        {
          supplier: item.company.id,
          branch: branches[0],
          cart: [{
            product: item.product.id,
            unit: item.unit.id,
            quantity: item.quantity,
            value: item.value,
          }],
        },
      ]
    }, [])


    createOrders(orders)
  }

  changeQty(item, event) {
    const { addToCart } = this.props

    addToCart({
      ...item,
      quantity: event.target.value,
    })
  }

  changeSupplier(item, event) {
    const { addToCart } = this.props

    const company = item.companies.find(c => c.id === event.target.value)

    addToCart({
      ...item,
      value: company.value,
      company,
    })
  }

  render() {
    const { classes, cart, role } = this.props

    const total = cart.reduce((acc, item) => acc + item.value * item.quantity, 0)

    const suppliers = cart.reduce((acc, item) => {
      if (acc.some(c => c.id === item.company.id)) {
        return acc
      }

      return [
        ...acc,
        item.company,
      ]
    }, [])

    const CartList = cart.map(item => (
      <div key={item.product.id + item.unit.id}>
        <ListItem className={classes.item}>
          <div className={classes.float}>
            <ClearIcon className={classes.close} onClick={event => this.onDeleteItem(item.product.id, event)}> </ClearIcon>
            <Typography variant="body1">
              {role !== 'chef' ?
                `${item.product.name}, ${item.value} тг/${item.unit.name}` :
                `${item.product.name}`}
            </Typography>
            <Typography variant="body2">
              {role !== 'chef' ?
                `Сумма - ${item.value * item.quantity} тг` :
                null}
            </Typography>
            <TextField
              label="Количество"
              InputProps={{
                endAdornment: <InputAdornment position="end">{item.unit.name}</InputAdornment>,
              }}
              value={item.quantity}
              type="number"
              onChange={event => this.changeQty(item, event)}
              className={classes.qtyInput}
            />
          </div>


          {role !== 'chef' ?
            (
              <FormControl className={classes.input}>
                <InputLabel htmlFor="company">Поставщик</InputLabel>
                <Select
                  native
                  value={item.company.id}
                  inputProps={{
                    name: 'company',
                    id: 'company',
                  }}
                  onChange={event => this.changeSupplier(item, event)}
                >
                  <option value=""> </option>
                  {item.companies.map(c => <option value={c.id} key={c.id}>{c.name}</option>)}
                </Select>
              </FormControl>
            )
            : null}

        </ListItem>
        <Divider className={classes.divider} />
      </div>
    ))

    return (
      <div className={classes.root}>
        <List>
          {CartList}
        </List>

        <Typography variant="body1" className={classes.total}>
          {role !== 'chef' ? `Итого - ${total} тг` : null}
        </Typography>

        <Button variant="contained" color="secondary" className={classes.button} onClick={this.onCreateOrders}>
          {role !== 'chef' ? `Создать заявки (${suppliers.length})` : 'Создать заявки'}
        </Button>
      </div>
    )
  }
}

Cart.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  cart: PropTypes.arrayOf(PropTypes.object),
  addToCart: PropTypes.func.isRequired,
  branches: PropTypes.arrayOf(PropTypes.string),
  createOrders: PropTypes.func.isRequired,
  ordersCreated: PropTypes.bool,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  clearCart: PropTypes.func.isRequired,
  role: PropTypes.string,
}

Cart.defaultProps = {
  cart: [],
  branches: [],
  ordersCreated: false,
  role: '',
}

const mapStateToProps = state => ({
  apiSet: state.session.apiSet,
  products: state.product.products,
  cart: state.order.cart,
  branches: state.session.branches,
  ordersCreated: state.order.ordersCreated,
  role: state.session.role,
})

export default connect(mapStateToProps, {
  addToCart: orderActions.addToCart,
  createOrders: orderActions.createOrders,
  clearCart: orderActions.clearCart,
  deleteFromCart: orderActions.deleteFromCart
})(withStyles(styles)(Cart))
