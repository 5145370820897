import * as Sentry from '@sentry/browser'
import { takeLatest, takeEvery, put, call } from 'redux-saga/effects'
import * as types from './types'
import userAPI from '../../api/user'
import * as notificationTypes from '../notification/types'

function* login(action) {
  try {
    const response = yield call(userAPI.login, action.payload)
    yield put({
      type: types.LOGIN_USER_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    if (error.response.status === 404) {
      return yield put({
        type: notificationTypes.NOTIFY,
        payload: 'Пользователь не найден.',
      })
    }

    Sentry.captureException(error)

    yield put({
      type: types.LOGIN_USER_ERROR,
      payload: error.response,
    })
  }
}

function* get(action) {
  try {
    const response = yield call(userAPI.getSession, action.payload)
    return yield put({
      type: types.GET_SESSION_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 404) {
      return yield put({ type: types.LOGOUT_USER })
    }

    Sentry.captureException(error)

    return yield put({
      type: types.GET_SESSION_ERROR,
      payload: error.response,
    })
  }
}

function* setPassword(action) {
  try {
    const response = yield call(userAPI.setPassword, action.payload)
    yield put({
      type: types.SET_PASSWORD_SUCCESS,
      payload: response.data,
    })

    return yield put({
      type: notificationTypes.NOTIFY,
      payload: 'Пароль создан.',
    })
  } catch (error) {
    if (error.response.status === 401) {
      return yield put({ type: types.LOGOUT_USER })
    }

    Sentry.captureException(error)

    return yield put({
      type: types.SET_PASSWORD_ERROR,
      payload: error.response,
    })
  }
}

export default function* sessionSaga() {
  yield takeLatest(types.LOGIN_USER, login)
  yield takeEvery(types.GET_SESSION, get)
  yield takeLatest(types.SET_PASSWORD, setPassword)
}
