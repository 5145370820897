import * as Sentry from '@sentry/browser'
import { takeEvery, put, call, takeLatest } from 'redux-saga/effects'
import * as types from './types'
import * as notificationTypes from '../notification/types'
import supplierAPI from '../../api/supplier'

function* getSuppliers(action) {
  try {
    const response = yield call(supplierAPI.getSuppliers, action.payload)
    return yield put({
      type: types.GET_SUPPLIERS_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.GET_SUPPLIERS_ERROR,
      payload: error.response,
    })
  }
}

function* create(action) {
  try {
    const response = yield call(supplierAPI.create, action.payload)

    yield put({
      type: types.CREATE_SUPPLIER_SUCCESS,
      payload: response.data,
    })

    return yield put({
      type: notificationTypes.NOTIFY,
      payload: 'Поставщик создан.',
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.CREATE_SUPPLIER_ERROR,
      payload: error.response,
    })
  }
}

function* get(action) {
  try {
    const response = yield call(supplierAPI.get, action.payload)
    return yield put({
      type: types.GET_SUPPLIER_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.GET_SUPPLIER_ERROR,
      payload: error.response,
    })
  }
}

function* update(action) {
  try {
    const response = yield call(supplierAPI.update, action.payload)

    yield put({
      type: types.UPDATE_SUPPLIER_SUCCESS,
      payload: response.data,
    })

    return yield put({
      type: notificationTypes.NOTIFY,
      payload: 'Поставщик сохранен.',
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.UPDATE_SUPPLIER_ERROR,
      payload: error.response,
    })
  }
}

function* getMembers(action) {
  try {
    const response = yield call(supplierAPI.getMembers, action.payload)
    return yield put({
      type: types.GET_SUPPLIER_MEMBERS_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.GET_SUPPLIER_MEMBERS_ERROR,
      payload: error.response,
    })
  }
}

function* createMember(action) {
  try {
    const response = yield call(supplierAPI.createMember, action.payload)

    yield put({
      type: types.CREATE_SUPPLIER_MEMBER_SUCCESS,
      payload: response.data,
    })

    return yield put({
      type: notificationTypes.NOTIFY,
      payload: 'Представитель создан.',
    })
  } catch (error) {
    Sentry.captureException(error)

    yield put({
      type: notificationTypes.NOTIFY,
      payload: 'Представитель с таким номером уже существует!',
    })
    return yield put({
      type: types.CREATE_SUPPLIER_MEMBER_ERROR,
      payload: error.response,
    })
  }
}

function* getMember(action) {
  try {
    const response = yield call(supplierAPI.getMember, action.payload)
    return yield put({
      type: types.GET_SUPPLIER_MEMBER_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.GET_SUPPLIER_MEMBER_ERROR,
      payload: error.response,
    })
  }
}

function* updateMember(action) {
  try {
    const response = yield call(supplierAPI.updateMember, action.payload)

    yield put({
      type: types.UPDATE_SUPPLIER_MEMBER_SUCCESS,
      payload: response.data,
    })

    return yield put({
      type: notificationTypes.NOTIFY,
      payload: 'Представитель сохранен.',
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.UPDATE_SUPPLIER_MEMBER_ERROR,
      payload: error.response,
    })
  }
}

function* getPrices(action) {
  try {
    const response = yield call(supplierAPI.getPrices, action.payload)
    return yield put({
      type: types.GET_SUPPLIER_PRICES_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.GET_SUPPLIER_PRICES_ERROR,
      payload: error.response,
    })
  }
}

function* createPrice(action) {
  try {
    const response = yield call(supplierAPI.createPrice, action.payload)

    yield put({
      type: types.CREATE_SUPPLIER_PRICE_SUCCESS,
      payload: response.data,
    })

    return yield put({
      type: notificationTypes.NOTIFY,
      payload: 'Прайс создан.',
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.CREATE_SUPPLIER_PRICE_ERROR,
      payload: error.response,
    })
  }
}

function* getPrice(action) {
  try {
    const response = yield call(supplierAPI.getPrice, action.payload)
    return yield put({
      type: types.GET_SUPPLIER_PRICE_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.GET_SUPPLIER_PRICE_ERROR,
      payload: error.response,
    })
  }
}

function* updatePrice(action) {
  try {
    const response = yield call(supplierAPI.updatePrice, action.payload)

    yield put({
      type: types.UPDATE_SUPPLIER_PRICE_SUCCESS,
      payload: response.data,
    })

    return yield put({
      type: notificationTypes.NOTIFY,
      payload: 'Прайс сохранен.',
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.UPDATE_SUPPLIER_PRICE_ERROR,
      payload: error.response,
    })
  }
}

export default function* supplierSaga() {
  yield takeEvery(types.GET_SUPPLIERS, getSuppliers)
  yield takeLatest(types.CREATE_SUPPLIER, create)
  yield takeEvery(types.GET_SUPPLIER, get)
  yield takeLatest(types.UPDATE_SUPPLIER, update)

  yield takeEvery(types.GET_SUPPLIER_MEMBERS, getMembers)
  yield takeLatest(types.CREATE_SUPPLIER_MEMBER, createMember)
  yield takeEvery(types.GET_SUPPLIER_MEMBER, getMember)
  yield takeLatest(types.UPDATE_SUPPLIER_MEMBER, updateMember)

  yield takeEvery(types.GET_SUPPLIER_PRICES, getPrices)
  yield takeLatest(types.CREATE_SUPPLIER_PRICE, createPrice)
  yield takeEvery(types.GET_SUPPLIER_PRICE, getPrice)
  yield takeLatest(types.UPDATE_SUPPLIER_PRICE, updatePrice)
}
