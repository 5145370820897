import api from './api'

const productAPI = {
  get: params => api.get(`/product`, {
    params,
  }),
  getProducts: params => api.get(`/products`, {
    params,
  }),
  create: data => api.post(`/product`, data),
  update: data => api.put(`/product`, data),
  upload: data => api.post(`/upload-product-photo`, data),
  getUnits: params => api.get(`/units`, {
    params,
  }),
  getGroups: params => api.get(`/groups`, {
    params,
  }),
}

export default productAPI
