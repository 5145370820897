import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Fab,
  withStyles,
  TextField,
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core'
import { Check as CheckIcon } from '@material-ui/icons'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { userActions } from '../../redux/user'
import { branchActions } from '../../redux/branch'
import PhoneInput from '../../components/PhoneInput'

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    position: 'fixed',
    bottom: 0,
    top: 56,
    width: '100%',
    maxWidth: 600,
    margin: '0 auto',
    left: 0,
    right: 0,
    background: 'white',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  button: {
    width: '100%',
    top: theme.spacing(4),
  },
  form: {
    '& .MuiFormControl-root': {
      width: '100%',
      marginBottom: theme.spacing(3),
    },
    '& .MuiButton-root': {
      marginTop: theme.spacing(3),
    },
  },
})

class User extends Component {
  constructor(props) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
    this.formValid = this.formValid.bind(this)
    this.onCheckboxChange = this.onCheckboxChange.bind(this)
    this.onArchiveUser = this.onArchiveUser.bind(this)
  }

  componentDidMount() {
    const {
      id,
      history,
      location,
      apiSet,
      get,
      match,
      getBranches,
    } = this.props

    if (id && location.pathname === '/new-user') {
      history.replace(`/user/${id}`)
    }

    if (apiSet) {
      getBranches()

      if (match.params.id && !id) {
        get({ id: match.params.id })
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      id,
      history,
      location,
      get,
      match,
      apiSet,
      getBranches,
    } = this.props

    if (id && location.pathname === '/new-user') {
      history.replace(`/user/${id}`)
    }

    if (apiSet && apiSet !== prevProps.apiSet) {
      getBranches()

      if (match.params.id && !id) {
        get({ id: match.params.id })
      }
    }
  }

  onSubmit(event) {
    event.preventDefault()
    const {
      create, update, location, id, name, phone, role, userBranches: branches,
    } = this.props

    const user = {
      id, name, phone, role, branches,
    }

    if (location.pathname === '/new-user') {
      return create(user)
    }

    return update(user)
  }

  onCheckboxChange(event) {
    event.preventDefault()

    const { userBranches, change } = this.props

    const newBranches = userBranches.filter(ubr => ubr !== event.target.value)

    if (newBranches.length === userBranches.length) {
      newBranches.push(event.target.value)
    }

    change({
      name: 'branches',
      value: newBranches,
    })
  }

  onArchiveUser(event) {
    event.preventDefault()

    const { archive, id, name, phone, history, role, userBranches: branches } = this.props
    const user = {
      id, name, phone, role, branches,
    }

    archive(user)
    history.go(-1)
  }

  formValid() {
    const { name, phone, role } = this.props

    if (!name) return false
    if (!phone) return false
    if (!role) return false

    return true
  }

  render() {
    const {
      classes, change, name, phone, role, branches, userBranches, isDisabled, location
    } = this.props

    const isError = _field => {
      let helperText

      // if (field === 'phone' && error === 404) {
      //   helperText = 'Номер не найден'
      // }

      // if (field === 'password' && error === 403) {
      //   helperText = 'Неверный пароль'
      // }

      return helperText
    }

    const roles = [
      { value: 'chef', text: 'Шеф-Повар' },
      { value: 'manager', text: 'Менеджер' },
      { value: 'admin', text: 'Администратор' },
    ]

    const onFieldChange = fieldName => event => {
      change({
        name: fieldName,
        value: event.target.value,
      })
    }

    const checkedBranches = branches.map(br => ({
      ...br,
      checked: userBranches.some(ubr => ubr === br.id),
    }))

    return (
      <div className={classes.root}>
        <form onSubmit={this.onSubmit} className={classes.form}>
          <TextField disabled={isDisabled} error={!!isError('name')} label="Имя" value={name} onChange={onFieldChange('name')} helperText={isError('name')} />
          <TextField
            error={!!isError('phone')}
            label="Телефон"
            disabled={isDisabled}
            value={phone}
            onChange={onFieldChange('phone')}
            helperText={isError('phone')}
            InputProps={{
              startAdornment: <InputAdornment position="start">+7</InputAdornment>,
              inputComponent: PhoneInput,
            }}
          />

          <FormControl>
            <InputLabel htmlFor="role">Роль</InputLabel>
            <Select
              native
              value={role}
              disabled={isDisabled}
              onChange={onFieldChange('role')}
              inputProps={{
                name: 'role',
                id: 'role',
              }}
            >
              <option value=""> </option>
              {roles.map(r => <option value={r.value} key={r.value}>{r.text}</option>)}
            </Select>
          </FormControl>

          <Typography variant="subtitle2">Объекты</Typography>

          {checkedBranches.map(br => (
            <FormControlLabel
              control={(
                <Checkbox
                  checked={br.checked}
                  disabled={isDisabled}
                  onChange={this.onCheckboxChange}
                  value={br.id}
                  color="primary"
                />
              )}
              label={br.name}
              key={br.id}
            />
          ))}

          {!isDisabled ? (
            <Fab color="secondary" className={classes.fab} disabled={!this.formValid()} type="submit">
              <CheckIcon />
            </Fab>
          )
            : null}
        </form>

        {
          !isDisabled ? ( location.pathname !== '/new-user' ?
          <Button variant="contained" className={classes.button} onClick={this.onArchiveUser}>
            Удалить пользователя
          </Button> : null
        )
          : <Typography variant="subtitle">Пользователь заархивирован</Typography>
        }
      </div>
    )
  }
}

User.propTypes = {
  apiSet: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  change: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  archive: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
  role: PropTypes.string,
  getBranches: PropTypes.func.isRequired,
  branches: PropTypes.arrayOf(PropTypes.object),
  userBranches: PropTypes.arrayOf(PropTypes.string),
  isDisabled: PropTypes.bool,
}

User.defaultProps = {
  apiSet: false,
  id: null,
  name: '',
  phone: '',
  role: '',
  branches: [],
  userBranches: [],
  isDisabled: false,
}

const mapStateToProps = state => ({
  apiSet: state.session.apiSet,
  id: state.user.user.id,
  name: state.user.user.name,
  phone: state.user.user.phone,
  role: state.user.user.role,
  branches: state.branch.branches,
  userBranches: state.user.user.branches,
  isDisabled: state.user.user.isDisabled,
})

export default withRouter(connect(mapStateToProps, {
  get: userActions.get,
  change: userActions.change,
  create: userActions.create,
  update: userActions.update,
  archive: userActions.archive,
  getBranches: branchActions.getBranches,
})(withStyles(styles)(User)))
