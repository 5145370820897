import * as Sentry from '@sentry/browser'
import { takeEvery, put, call, takeLatest } from 'redux-saga/effects'
import * as types from './types'
import userAPI from '../../api/user'
import * as notificationTypes from '../notification/types'

function* getUsers(action) {
  try {
    const response = yield call(userAPI.getUsers, action.payload)
    return yield put({
      type: types.GET_USERS_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.GET_USERS_ERROR,
      payload: error.response,
    })
  }
}

function* create(action) {
  try {
    const response = yield call(userAPI.create, action.payload)

    yield put({
      type: types.CREATE_USER_SUCCESS,
      payload: response.data,
    })

    return yield put({
      type: notificationTypes.NOTIFY,
      payload: 'Пользователь создан.',
    })
  } catch (error) {
    if (error.response.status === 400) {
      return yield put({
        type: notificationTypes.NOTIFY,
        payload: 'Такой номер уже существует.',
      })
    }

    Sentry.captureException(error)

    return yield put({
      type: notificationTypes.NOTIFY,
      payload: 'Произошла ошибка.',
    })
  }
}

function* update(action) {
  try {
    const response = yield call(userAPI.update, action.payload)

    yield put({
      type: types.UPDATE_USER_SUCCESS,
      payload: response.data,
    })

    return yield put({
      type: notificationTypes.NOTIFY,
      payload: 'Пользователь сохранен.',
    })
  } catch (error) {
    Sentry.captureException(error)

    yield put({
      type: notificationTypes.NOTIFY,
      payload: 'Произошла ошибка.',
    })

    return yield put({
      type: types.UPDATE_USER_ERROR,
      payload: error.response,
    })
  }
}

function* archive(action) {
  try {
    const response = yield call(userAPI.archive, action.payload)

    yield put({
      type: types.DELETE_USER_SUCCESS,
      payload: response.data,
    })

    return yield put({
      type: notificationTypes.NOTIFY,
      payload: 'Пользователь заархивирован.',
    })
  } catch (error) {
    Sentry.captureException(error)

    yield put({
      type: notificationTypes.NOTIFY,
      payload: 'Произошла ошибка.',
    })

    return yield put({
      type: types.DELETE_USER_ERROR,
      payload: error.response,
    })
  }
}

function* get(action) {
  try {
    const response = yield call(userAPI.get, action.payload)
    return yield put({
      type: types.GET_USER_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    Sentry.captureException(error)

    yield put({
      type: notificationTypes.NOTIFY,
      payload: 'Произошла ошибка.',
    })

    return yield put({
      type: types.GET_USER_ERROR,
      payload: error.response,
    })
  }
}

export default function* userSaga() {
  yield takeEvery(types.GET_USERS, getUsers)
  yield takeLatest(types.CREATE_USER, create)
  yield takeLatest(types.UPDATE_USER, update)
  yield takeLatest(types.DELETE_USER, archive)
  yield takeEvery(types.GET_USER, get)
}
