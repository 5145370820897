import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/browser'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import store from './redux/store'
import { env } from './local'

if (env === 'prod') {
  Sentry.init({ dsn: 'https://0645f936798345e7bf327b330d36697d@sentry.io/2581890' })
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
