import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Fab,
  withStyles,
  TextField,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core'
import { Check as CheckIcon } from '@material-ui/icons'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { supplierActions } from '../../redux/supplier'
import { productActions } from '../../redux/product'

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    position: 'fixed',
    bottom: 0,
    top: 56,
    width: '100%',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  form: {
    '& .MuiFormControl-root': {
      width: '100%',
      marginBottom: theme.spacing(3),
    },
    '& .MuiButton-root': {
      marginTop: theme.spacing(3),
    },
  },
})

class Price extends Component {
  constructor(props) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
    this.formValid = this.formValid.bind(this)
  }

  componentDidMount() {
    const {
      id,
      history,
      location,
      apiSet,
      get,
      match,
      getUnits,
      getProducts,
    } = this.props

    if (id && location.pathname === '/new-price') {
      history.replace(`/price/${id}`)
    }

    if (apiSet) {
      getUnits()
      getProducts()

      if (match.params.id && !id) {
        get({ id: match.params.id })
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      id,
      history,
      location,
      get,
      match,
      apiSet,
      getUnits,
      getProducts,
    } = this.props

    if (id && location.pathname === '/new-price') {
      history.replace(`/price/${id}`)
    }

    if (apiSet && apiSet !== prevProps.apiSet) {
      getUnits()
      getProducts()

      if (match.params.id && !id) {
        get({ id: match.params.id })
      }
    }
  }

  onSubmit(event) {
    event.preventDefault()
    const {
      create, update, location, id, product, unit, value, company, history
    } = this.props
    const price = {
      id, value, product, unit, company,
    }

    if (location.pathname === '/new-price') {
      return create(price, history.go(-1))
    }

    return update(price)
  }

  formValid() {
    const { value, product, unit } = this.props

    if (!value) return false
    if (!product) return false
    if (!unit) return false

    return true
  }

  render() {
    const {
      classes, change, value, product, unit, products, units,
    } = this.props
    const isError = _field => {
      let helperText

      // if (field === 'phone' && error === 404) {
      //   helperText = 'Номер не найден'
      // }

      // if (field === 'password' && error === 403) {
      //   helperText = 'Неверный пароль'
      // }

      return helperText
    }

    const onFieldChange = fieldName => event => {
      if (fieldName === 'product') {
        const selectedProduct = products.find(p => p.id === event.target.value)

        change({
          name: 'unit',
          value: selectedProduct.unit,
        })
      }
      change({
        name: fieldName,
        value: fieldName === 'value' ? parseInt(event.target.value) : event.target.value,
      })
    }

    const onFocusPrice = e => {
      if (e.target.value === '0') {
        e.target.value = ''
      }
    };

    return (
      <div className={classes.root}>
        <form onSubmit={this.onSubmit} className={classes.form}>
          <FormControl>
            <InputLabel htmlFor="product">Товар</InputLabel>
            <Select
              native
              value={product}
              onChange={onFieldChange('product')}
              inputProps={{
                name: 'product',
                id: 'product',
              }}
            >
              <option value=""> </option>
              {products.map(p => <option value={p.id} key={p.id}>{p.name}</option>)}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel htmlFor="unit">Единица измерения</InputLabel>
            <Select
              native
              value={unit}
              onChange={onFieldChange('unit')}
              inputProps={{
                name: 'unit',
                id: 'unit',
              }}
            >
              <option value=""> </option>
              {units.map(u => <option value={u.id} key={u.id}>{u.name}</option>)}
            </Select>
          </FormControl>

          <TextField error={!!isError('value')} label="Цена" value={value} onChange={onFieldChange('value')} helperText={isError('value')} onClick={onFocusPrice} type="number"/>

          <Fab color="secondary" className={classes.fab} disabled={!this.formValid()} type="submit">
            <CheckIcon />
          </Fab>
        </form>
      </div>
    )
  }
}

Price.propTypes = {
  apiSet: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  change: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string,
  value: PropTypes.number,
  product: PropTypes.string,
  unit: PropTypes.string,
  company: PropTypes.string,
  getUnits: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object),
  units: PropTypes.arrayOf(PropTypes.object),
}

Price.defaultProps = {
  apiSet: false,
  id: null,
  value: 0,
  product: '',
  unit: '',
  company: null,
  products: [],
  units: [],
}

const mapStateToProps = state => ({
  apiSet: state.session.apiSet,
  id: state.supplier.price.id,
  value: state.supplier.price.value,
  product: state.supplier.price.product,
  unit: state.supplier.price.unit,
  company: state.supplier.id,
  units: state.product.units,
  products: state.product.products,
})

export default withRouter(connect(mapStateToProps, {
  get: supplierActions.getPrice,
  change: supplierActions.changePrice,
  create: supplierActions.createPrice,
  update: supplierActions.updatePrice,
  getUnits: productActions.getUnits,
  getProducts: productActions.getProducts,
})(withStyles(styles)(Price)))
