import * as types from './types'
import { sameProduct } from '../../helpers'

const initialState = {
  order: {},
  cart: [],
}

export default function orderReducer(state = initialState, action) {
  const { payload } = action

  switch (action.type) {
    case types.GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: payload,
        error: null,
        ordersCreated: false,
      }

    case types.GET_ORDERS_ERROR:
      return { ...state, error: payload.status }

    case types.GET_MARKET_SUCCESS:
      return {
        ...state,
        market: payload,
      }

    case types.GET_MARKET_ERROR:
      return { ...state, error: payload.status }

    case types.ADD_TO_CART:
      const newCart = state.cart.some((item) => sameProduct(item, payload))
        ? state.cart.map((item) => {
            if (sameProduct(item, payload)) {
              return { ...payload }
            }

            return item
          })
        : [...state.cart, payload]

      return {
        ...state,
        cart: newCart,
      }

    case types.DELETE_FROM_CART:
      const cart = state.cart.filter((item) => item.product.id !== payload.item)
      return {
        ...state,
        cart,
      }

    case types.CHANGE_SEARCH:
      return {
        ...state,
        searchText: payload,
      }

    case types.SET_MARKET_GROUP:
      const group = state.group === payload ? null : payload

      return {
        ...state,
        group,
      }

    case types.CREATE_ORDERS_SUCCESS:
      return {
        ...state,
        ordersCreated: true,
      }

    case types.CREATE_ADDITIONAL_ORDER_SUCCESS:
      return {
        ...state,
        ordersCreated: true,
      }
    case types.CREATE_ADDITIONAL_ORDER_ERROR:
      return { ...state, error: payload.status, ordersCreated: false }
    case types.CREATE_ORDERS_ERROR:
      return { ...state, error: payload.status, ordersCreated: false }

    case types.CLEAR_CART:
      return { ...state, cart: [] }

    case types.GET_ORDER_SUCCESS:
      return {
        ...state,
        ...payload,
        error: null,
        original: payload,
      }

    case types.GET_ORDER_ERROR:
      return { ...state, error: payload.status }

    case types.GET_MAIN_ORDER_SUCCESS:
      return {
        ...state,
        ...payload,
        error: null,
      }
    case types.GET_MAIN_ORDER_ERROR:
      return { ...state, error: payload.status }

    case types.CHANGE_ORDER_DETAILS:
      const newDetails = state.details.map((item) => {
        if (item.id === payload.id) {
          return { ...payload }
        }

        return item
      })

      return {
        ...state,
        details: newDetails,
      }
    case types.UPDATE_ORDER_SUCCESS:
      const changedOrder = state.orders?.map((main) => {
        if (main.id === payload.mainID) {
          main.orders.map((order) => {
            if (order.id === payload.id) {
              order.status = payload.status
            }
            return order
          })
        }
        return main
      })
      return {
        ...state,
        orders: changedOrder,
      }

    case types.UPDATE_ORDER_ERROR:
      return { ...state, error: payload.status }

    case types.SEND_ORDER_SUCCESS:
      const orders = state.orders.map((order) => {
        if (order.id === payload) {
          return {
            ...order,
            sent: true,
          }
        }

        return order
      })

      return {
        ...state,
        orders,
      }
    case types.SEND_ORDER_ERROR:
      return { ...state, error: payload.status }

    case types.GET_REPORT_SUCCESS:
      return {
        ...state,
        report: payload,
        error: null,
      }

    case types.GET_REPORT_ERROR:
      return { ...state, error: payload.status }

    case types.SEND_ARCHIVE_ORDER_SUCCESS:
      const items = state.orders.map((order) => {
        if (order.id === payload) {
          return {
            ...order,
            archived: true,
          }
        }

        return order
      })

      return {
        ...state,
        orders: items,
      }

    case types.SEND_ARCHIVE_ORDER_ERROR:
      return { ...state, error: payload.status }

    case types.NOTIFY_SUPPLIER_STATUS_SUCCESS:
    case types.GET_ORDER_STATUS_SUCCESS:
      const newOrders = state.orders.map((main) => {
        if (main.id === payload.id) {
          main.status = payload.status
          main.orders.map((order) => {
            if (order.id === payload.order.id) {
              order.status = payload.order.status
            }
            return order
          })
        }
        return main
      })
      return {
        ...state,
        orders: newOrders,
      }

    case types.NOTIFY_SUPPLIER_STATUS_ERROR:
    case types.GET_ORDER_STATUS_ERROR:
      return { ...state, error: payload.status }

    case types.SET_OPENED_MAIN_ORDERS:
      return { ...state, openedOrders: payload.openedOrders }

    default:
      return {
        ...state,
      }
  }
}
