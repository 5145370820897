import api from './api'

const orderAPI = {
  getOrders: params => api.get(`/orders`, {
    params,
  }),
  getMarket: params => api.get(`/market`, {
    params,
  }),
  createOrders: data => api.post(`/orders`, data),
  get: params => api.get(`/order`, {
    params,
  }),
  getMainOrder: params => api.get(`/main-order`, {
    params,
  }),
  update: data => api.put(`/order`, data),
  additional: data => api.post(`/additional`, data),
  changeOrder: data => api.put(`/orderStatus`, data),
  notifySupplierStatus: data => api.post(`/notify-supplier`, data),
  send: data => api.post(`/send-order`, data),
  getReport: params => api.get(`/order-report`, {
    params,
  }),
  sendToArchive: data => api.post('/send-archive', data)
}

export default orderAPI
