import { combineReducers } from 'redux'
import { sessionReducer } from './session'
import { userReducer } from './user'
import { notificationReducer } from './notification'
import { branchReducer } from './branch'
import { supplierReducer } from './supplier'
import { cityReducer } from './city'
import { productReducer } from './product'
import { orderReducer } from './order'

export default combineReducers({
  session: sessionReducer,
  user: userReducer,
  notification: notificationReducer,
  branch: branchReducer,
  supplier: supplierReducer,
  city: cityReducer,
  product: productReducer,
  order: orderReducer,
})
