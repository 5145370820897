import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  withStyles,
  Typography,
  List,
  ListItem,
  TextField,
  InputAdornment,
  Divider,
  Fab,
} from '@material-ui/core'
import { Check as CheckIcon } from '@material-ui/icons'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { orderActions } from '../../redux/order'
import { describeStatus } from '../../helpers'

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    width: '100%',
    margin: '60px auto',
    maxWidth: 600,
    background: 'white',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  form: {
    '& .MuiFormControl-root': {
      width: '100%',
      marginBottom: theme.spacing(3),
    },
    '& .MuiButton-root': {
      marginTop: theme.spacing(3),
    },
  },
  fileInput: {
    display: 'none',
  },
  img: {
    width: '100%',
  },
  float: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  item: {
    flexDirection: 'column',
    alignItems: 'start',
  },
})

class ArchivedOrderView extends Component {
  constructor(props) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount() {
    const { get, apiSet, match } = this.props

    if (apiSet) {
      get({ id: match.params.id })
    }
  }

  componentDidUpdate(prevProps) {
    const { get, apiSet, match } = this.props

    if (apiSet && apiSet !== prevProps.apiSet) {
      get({ id: match.params.id })
    }
  }

  onSubmit(event) {
    const {
      match, update, details, status,
    } = this.props

    event.preventDefault()

    update({
      id: match.params.id,
      details,
      status,
    })
  }

  changeQty(item, event) {
    const { changeDetails } = this.props

    changeDetails({
      ...item,
      quantity: event.target.value,
    })
  }

  changeValue(item, event) {
    const { changeDetails } = this.props

    changeDetails({
      ...item,
      value: event.target.value,
    })
  }

  render() {
    const {
      classes, user, supplier, branch, status, details, role, original, change,
    } = this.props

    // eslint-disable-next-line
    const onFieldChange = fieldName => event => {
      change({
        name: fieldName,
        value: event.target.value,
      })
    }

    const formChanged = () => {
      if (status !== original.status) {
        return true
      }

      if (!_.isEqual(details, original.details)) {
        return true
      }

      return false
    }

    const detailList = details.map(d => (
      <div key={d.id}>
        <ListItem className={classes.item}>
          <div className={classes.float}>
            <Typography variant="body1">
              {`${d.product.name}`}
            </Typography>
            <Typography variant="body2">
              {role !== 'chef' ? `${d.value * d.quantity} тг` : null}
            </Typography>
          </div>

          <TextField
            label="Количество"
            InputProps={{
              endAdornment: <InputAdornment position="end">{d.unit.name}</InputAdornment>,
            }}
            value={d.quantity}
            type="number"
            onChange={event => this.changeQty(d, event)}
            className={classes.input}
            disabled={status !== 'new'}
          />

          {role !== 'chef' ?
            (
              <TextField
                label="Цена"
                InputProps={{
                  endAdornment: <InputAdornment position="end">тг</InputAdornment>,
                }}
                value={d.value}
                type="number"
                onChange={event => this.changeValue(d, event)}
                className={classes.input}
                disabled
              />
            ) :
            null}
        </ListItem>
        <Divider />
      </div>
    ))

    const total = details.reduce((sum, d) => sum + d.quantity * d.value, 0)

    return (
      <div className={classes.root}>
        <form className={classes.form}>
          <div className={classes.float}>
            <Typography variant="body2">
              Автор
            </Typography>

            <Typography variant="body1">
              {user}
            </Typography>
          </div>

          <div className={classes.float}>
            <Typography variant="body2">
              Поставщик
            </Typography>

            <Typography variant="body1">
              {supplier}
            </Typography>
          </div>

          <div className={classes.float}>
            <Typography variant="body2">
              Объект
            </Typography>

            <Typography variant="body1">
              {branch}
            </Typography>
          </div>

          <div className={classes.float}>
            <Typography variant="body2">
              Статус
            </Typography>

            <Typography variant="body1">
              {describeStatus(status).text}
            </Typography>
          </div>

          {role !== 'chef' ?
            (
              <div className={classes.float}>
                <Typography variant="body2">
                  Общая сумма
                </Typography>

                <Typography variant="body1">
                  {`${total} тг`}
                </Typography>
              </div>
            ) : null}

          <Typography variant="body1">
            Детали
          </Typography>

          <List>
            {detailList}
          </List>

          <Fab color="secondary" className={classes.fab} onClick={this.onSubmit} disabled={!formChanged()}>
            <CheckIcon />
          </Fab>
        </form>
      </div>
    )
  }
}

ArchivedOrderView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  get: PropTypes.func.isRequired,
  apiSet: PropTypes.bool,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.string,
  supplier: PropTypes.string,
  branch: PropTypes.string,
  status: PropTypes.string,
  change: PropTypes.func.isRequired,
  details: PropTypes.arrayOf(PropTypes.any),
  changeDetails: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  role: PropTypes.string,
  original: PropTypes.objectOf(PropTypes.any),
}

ArchivedOrderView.defaultProps = {
  apiSet: false,
  user: '',
  supplier: '',
  branch: '',
  status: '',
  details: [],
  role: '',
  original: {},
}

const mapStateToProps = state => ({
  apiSet: state.session.apiSet,
  user: state.order.user,
  supplier: state.order.supplier,
  branch: state.order.branch,
  status: state.order.status,
  details: state.order.details,
  role: state.session.role,
  original: state.order.original,
})

export default withRouter(connect(mapStateToProps, {
  get: orderActions.get,
  change: orderActions.change,
  changeDetails: orderActions.changeDetails,
  update: orderActions.update,
})(withStyles(styles)(ArchivedOrderView)))
