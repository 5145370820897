import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { CssBaseline, ThemeProvider, createMuiTheme } from '@material-ui/core'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Main from './containers/Main'
import api from './api/api'
import { sessionActions } from './redux/session'
import { productActions } from './redux/product'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1565c0',
    },
    secondary: {
      main: '#558b2f',
    },
  },
})

class App extends React.Component {
  componentDidMount() {
    const {
      token, setAPIAuth, getUser, getGroups,
    } = this.props

    if (token) {
      api.defaults.headers.common.Authorization = token
      setAPIAuth(true)

      getUser()
      getGroups()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      token, setAPIAuth, getUser, getGroups,
    } = this.props

    if (token && prevProps.token !== token) {
      api.defaults.headers.common.Authorization = token
      setAPIAuth(!!token)

      getUser()
      getGroups()
    }
  }

  render() {
    return (
      <BrowserRouter>
        <CssBaseline>
          <ThemeProvider theme={theme}>
            <Main />
          </ThemeProvider>
        </CssBaseline>
      </BrowserRouter>
    )
  }
}

App.propTypes = {
  token: PropTypes.string,
  setAPIAuth: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  getGroups: PropTypes.func.isRequired,
}

App.defaultProps = {
  token: null,
}

const mapStateToProps = state => ({
  token: state.session.token,
  error: state.session.error,
})

export default connect(mapStateToProps, {
  setAPIAuth: sessionActions.setAPIAuth,
  getUser: sessionActions.get,
  getGroups: productActions.getGroups,
})(App)
