import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  List, ListItem, ListItemText, Divider, Fab, withStyles,
} from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { supplierActions } from '../../redux/supplier'

const styles = theme => ({
  root: {
    position: 'fixed',
    bottom: 0,
    top: 56,
    width: '100%',
    overflow: 'scroll',
    maxWidth: 600,
    margin: '0 auto',
    left: 0,
    right: 0,
    background: 'white',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
})

class Suppliers extends Component {
  componentDidMount() {
    const { apiSet, getSuppliers } = this.props

    if (apiSet) {
      getSuppliers()
    }
  }

  componentDidUpdate(prevProps) {
    const { apiSet, getSuppliers } = this.props

    if (apiSet && apiSet !== prevProps.apiSet) {
      getSuppliers()
    }
  }

  render() {
    let { suppliers, classes } = this.props
    suppliers.sort((a, b) => {
      if (a.priority < b.priority) {
        return 1
      } else if (a.priority === b.priority) {
        if (a.name > b.name) return 1
        return -1 
      }
      return -1
    })

    const supplierList = suppliers.map(s => (
      <div key={s.id}>
        <ListItem button component={RouterLink} to={`/supplier/${s.id}`}>
          <ListItemText primary={`${s.name}`} secondary={`Приоритет - ${s.priority}`} />
        </ListItem>
        <Divider />
      </div>
    ))

    return (
      <div className={classes.root}>
        <List>
          {supplierList}
        </List>

        <Fab color="secondary" className={classes.fab} component={RouterLink} to="/new-supplier">
          <AddIcon />
        </Fab>
      </div>
    )
  }
}

Suppliers.propTypes = {
  apiSet: PropTypes.bool,
  getSuppliers: PropTypes.func.isRequired,
  suppliers: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
}

Suppliers.defaultProps = {
  apiSet: false,
  suppliers: [],
}

const mapStateToProps = state => ({
  apiSet: state.session.apiSet,
  suppliers: state.supplier.suppliers,
})

export default connect(mapStateToProps, {
  getSuppliers: supplierActions.getSuppliers,
})(withStyles(styles)(Suppliers))
