import * as types from './types'

export const getProducts = payload => ({
  type: types.GET_PRODUCTS,
  payload,
})

export const change = payload => ({
  type: types.CHANGE_PRODUCT,
  payload,
})

export const create = payload => ({
  type: types.CREATE_PRODUCT,
  payload,
})

export const get = payload => ({
  type: types.GET_PRODUCT,
  payload,
})

export const update = payload => ({
  type: types.UPDATE_PRODUCT,
  payload,
})

export const upload = payload => ({
  type: types.UPLOAD_PRODUCT_PHOTO,
  payload,
})

export const getUnits = payload => ({
  type: types.GET_UNITS,
  payload,
})

export const getGroups = payload => ({
  type: types.GET_GROUPS,
  payload,
})
