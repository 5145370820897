import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  withStyles,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Badge,
  Chip,
} from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { orderActions } from '../../redux/order'
import { photosHost } from '../../local'
import ProductDialog from './ProductDialog'
import { sameProduct } from '../../helpers'

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    maxWidth: 600,
    margin: '65px auto',
  },
  card: {
    width: '100%',
    '& .MuiCardActionArea-root': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-between',
      alignItems: 'start',
    },
    '& img': {
      width: '100%',
    },
    '& svg': {
      width: '100%',
    },
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  form: {
    '& .MuiFormControl-root': {
      width: '100%',
      marginBottom: theme.spacing(3),
    },
    '& .MuiButton-root': {
      marginTop: theme.spacing(3),
    },
  },
  fileInput: {
    display: 'none',
  },
  img: {
    width: '100%',
  },
  badge: {
    width: '50vw',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '&:nth-child(2n+1)': {
      paddingLeft: 0,
    },
    '& .MuiBadge-badge': {
      marginTop: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
    },
  },
  chips: {
    width: '100%',
    margin: theme.spacing(1),
    '& .MuiChip-root': {
      margin: theme.spacing(0.5),
    },
  },
})

function Market(props) {
  const {
    classes,
    market,
    cart,
    searchText,
    groups,
    setGroup,
    group,
    apiSet,
    getMarket,
    role
  } = props

  const [state, setState] = useState({
    product: {},
    unit: {
      name: '',
    },
    quantity: '',
    open: false,
  })

  const {
    open, quantity, product, unit, value, photo, qty, company, companies,
  } = state

  useEffect(() => {
    if (apiSet) {
      getMarket()
    }
  }, [apiSet, getMarket])

  const change = (newQty) => {
    setState({
      ...state,
      quantity: newQty,
    })
  }


  const openDialog = pr => event => {
    event.preventDefault()

    const badgeQty = cart.some(item => sameProduct(item, pr))
      ? cart.find(item => sameProduct(item, pr)).quantity
      : ''

    setState({
      ...pr,
      open: true,
      quantity: badgeQty,
      qty: badgeQty,
    })
  }

  const closeDialog = event => {
    event.preventDefault()

    setState({
      open: false,
      product: {
        name: '',
      },
      unit: {
        name: '',
      },
      quantity: '',
      photo: null,
    })
  }

  const regEx = new RegExp(searchText, 'i')

  const searchedMarket = searchText ? market.filter(pr => pr.product.name.match(regEx))
    : (group ? market.filter(pr => pr.group === group) : [])

  const cardList = searchedMarket.map(pr => {
    const productPhoto = () => {
      if (pr.photo) {
        return <img src={`${photosHost}/${pr.photo}`} alt="Фото" />
      }

      return null
    }

    const badgeQty = cart.some(item => sameProduct(item, pr))
      ? cart.find(item => sameProduct(item, pr)).quantity
      : 0

    return (
      <Badge badgeContent={badgeQty} color="error" className={classes.badge} key={pr.id} max={999}>
        <Card className={classes.card}>
          <CardActionArea onClick={openDialog(pr)}>
            <CardMedia
              component={productPhoto}
            />
            <CardContent>
              <Typography gutterBottom variant="body2">
                {pr.product.name}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">

                { role !== 'chef' ?
                `${pr.value} тг/${pr.unit.name}` :
                `Единица измерения: ${pr.unit.name}` }

              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Badge>
    )
  })

  const price = {
    quantity,
    product,
    unit,
    value,
    photo,
    qty,
    company,
    companies,
  }

  const ChipList = groups.map(gr => {
    const variant = gr.id === group ? 'default' : 'outlined'

    return (
      <Chip
        label={gr.name}
        variant={variant}
        key={gr.id}
        onClick={() => setGroup(gr.id)}
      />
    )
  })

  return (
    <div className={classes.root}>
      <div className={classes.chips}>
        {ChipList}
      </div>

      {cardList}

      <ProductDialog
        price={price}
        open={open}
        onClose={closeDialog}
        onChange={newQty => change(newQty)}
        role={role}
      />
    </div>
  )
}

Market.propTypes = {
  apiSet: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  getMarket: PropTypes.func.isRequired,
  market: PropTypes.arrayOf(PropTypes.object),
  cart: PropTypes.arrayOf(PropTypes.object),
  searchText: PropTypes.string,
  groups: PropTypes.arrayOf(PropTypes.object),
  setGroup: PropTypes.func.isRequired,
  group: PropTypes.string,
  role: PropTypes.string,
}

Market.defaultProps = {
  apiSet: false,
  market: [],
  cart: [],
  searchText: '',
  groups: [],
  group: null,
  role: '',
}

const mapStateToProps = state => ({
  apiSet: state.session.apiSet,
  market: state.order.market,
  cart: state.order.cart,
  searchText: state.order.searchText,
  groups: state.product.groups,
  group: state.order.group,
  role: state.session.role,
})

export default withRouter(connect(mapStateToProps, {
  getMarket: orderActions.getMarket,
  setGroup: orderActions.setGroup,
})(withStyles(styles)(Market)))
