import { takeEvery, put, call, takeLatest } from 'redux-saga/effects'
import * as Sentry from '@sentry/browser'
import * as types from './types'
import orderAPI from '../../api/order'
import * as notificationTypes from '../notification/types'

function* getOrders(action) {
  try {
    const response = yield call(orderAPI.getOrders, action.payload)
    return yield put({
      type: types.GET_ORDERS_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    if (error.response.status === 404) {
      return null
    }

    Sentry.captureException(error)

    return yield put({
      type: types.GET_ORDERS_ERROR,
      payload: error.response,
    })
  }
}

function* getMarket(action) {
  try {
    const response = yield call(orderAPI.getMarket, action.payload)
    return yield put({
      type: types.GET_MARKET_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.GET_MARKET_ERROR,
      payload: error.response,
    })
  }
}

function* createOrders(action) {
  try {
    const response = yield call(orderAPI.createOrders, action.payload)
    yield put({
      type: types.CREATE_ORDERS_SUCCESS,
      payload: response.data,
    })

    return yield put({
      type: notificationTypes.NOTIFY,
      payload: 'Заявки созданы.',
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.CREATE_ORDERS_ERROR,
      payload: error.response,
    })
  }
}

function* createAdditionalOrders(action) {
  try {
    const response = yield call(orderAPI.additional, action.payload)
    yield put({
      type: types.CREATE_ADDITIONAL_ORDER_SUCCESS,
      payload: response.data,
    })

    return yield put({
      type: notificationTypes.NOTIFY,
      payload: 'Заявки созданы.',
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.CREATE_ADDITIONAL_ORDER_ERROR,
      payload: error.response,
    })
  }
}

function* get(action) {
  try {
    const response = yield call(orderAPI.get, action.payload)
    return yield put({
      type: types.GET_ORDER_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.GET_ORDER_ERROR,
      payload: error.response,
    })
  }
}

function* getMainOrder(action) {
  try {
    const response = yield call(orderAPI.getMainOrder, action.payload)
    return yield put({
      type: types.GET_MAIN_ORDER_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    return yield put({
      type: types.GET_MAIN_ORDER_ERROR,
      payload: error.response,
    })
  }
}

function* update(action) {
  try {
    const response = yield call(orderAPI.update, action.payload)
    yield put({
      type: types.UPDATE_ORDER_SUCCESS,
      payload: response.data,
    })
    yield put({
      type: types.GET_ORDER,
      payload: {
        id: action.payload.id,
      },
    })
    return yield put({
      type: notificationTypes.NOTIFY,
      payload: 'Заявка сохранена.',
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.UPDATE_ORDER_ERROR,
      payload: error.response,
    })
  }
}

function* send(action) {
  try {
    yield call(orderAPI.send, action.payload)

    yield put({
      type: types.SEND_ORDER_SUCCESS,
      payload: action.payload.id,
    })

    yield put({
      type: types.GET_ORDERS,
    })

    return yield put({
      type: notificationTypes.NOTIFY,
      payload: 'Заявка отправлена.',
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.UPDATE_ORDER_ERROR,
      payload: error.response,
    })
  }
}

function* sendToArchive(action) {
  try {
    const response = yield call(orderAPI.sendToArchive, action.payload)

    yield put({
      type: types.SEND_ARCHIVE_ORDER_SUCCESS,
      payload: response.data,
    })

    yield put({
      type: types.GET_ORDERS,
    })

    return yield put({
      type: notificationTypes.NOTIFY,
      payload: 'Заявка заархивирована.',
    })
  } catch (error) {
    Sentry.captureException(error)

    yield put({
      type: notificationTypes.NOTIFY,
      payload: 'Не удалось заархивировать заявку.',
    })
    return yield put({
      type: types.SEND_ARCHIVE_ORDER_ERROR,
      payload: error.response,
    })
  }
}

function* notifySupplierStatus(action) {
  try {
    const response = yield call(orderAPI.notifySupplierStatus, action.payload)

    yield put({
      type: types.GET_ORDER,
      payload: {
        id: action.payload.orderId,
      },
    })

    return yield put({
      type: types.NOTIFY_SUPPLIER_STATUS_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.NOTIFY_SUPPLIER_STATUS_ERROR,
      payload: error.response,
    })
  }
}

function* changeOrderStatus(action) {
  try {
    const response = yield call(orderAPI.changeOrder, action.payload)

    yield put({
      type: types.GET_ORDER,
      payload: {
        id: action.payload.orderID,
      },
    })

    return yield put({
      type: types.GET_ORDER_STATUS_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.GET_ORDER_STATUS_ERROR,
      payload: error.response,
    })
  }
}
function* getReport(action) {
  try {
    const response = yield call(orderAPI.getReport, action.payload)
    return yield put({
      type: types.GET_REPORT_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    Sentry.captureException(error)

    return yield put({
      type: types.GET_REPORT_ERROR,
      payload: error.response,
    })
  }
}

export default function* orderSaga() {
  yield takeEvery(types.GET_ORDERS, getOrders)
  yield takeEvery(types.CHANGE_ORDER, changeOrderStatus)
  yield takeEvery(types.GET_MARKET, getMarket)
  yield takeLatest(types.CREATE_ORDERS, createOrders)
  yield takeLatest(types.CREATE_ADDITIONAL_ORDER, createAdditionalOrders)
  yield takeLatest(types.NOTIFY_SUPPLIER_STATUS, notifySupplierStatus)
  yield takeEvery(types.GET_ORDER, get)
  yield takeEvery(types.GET_MAIN_ORDER, getMainOrder)
  yield takeLatest(types.UPDATE_ORDER, update)
  yield takeLatest(types.SEND_ARCHIVE_ORDER, sendToArchive)
  yield takeLatest(types.SEND_ORDER, send)
  yield takeEvery(types.GET_REPORT, getReport)
}
