import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  withStyles, Snackbar,
} from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { notificationActions } from '../../redux/notification'

const styles = _theme => ({
  root: {
    marginTop: 60,
  },
})

class Notification extends Component {
  constructor() {
    super()

    this.onClose = this.onClose.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { message, setOpen } = this.props

    if (message && message !== prevProps.message) {
      setOpen(true)
    }
  }

  onClose() {
    const { setOpen } = this.props

    setOpen(false)
  }

  render() {
    const { message, open, classes } = this.props

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={open}
        onClose={this.onClose}
        message={message}
        autoHideDuration={3000}
        className={classes.root}
      />
    )
  }
}

Notification.propTypes = {
  message: PropTypes.string,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
}

Notification.defaultProps = {
  message: null,
  open: false,
}

const mapStateToProps = state => ({
  message: state.notification.message,
  open: state.notification.open,
})

export default withRouter(connect(mapStateToProps, {
  setOpen: notificationActions.setOpen,
})(withStyles(styles)(Notification)))
