import React from 'react'
import PropTypes from 'prop-types'
import {
  Backdrop,
  Card,
  CardContent,
  CardMedia,
  Typography,
  withStyles,
  TextField,
  InputAdornment,
  Button,
  IconButton,
} from '@material-ui/core'
import {
  ShoppingCartOutlined as CartIcon,
  Close as CloseIcon,
} from '@material-ui/icons'
import { connect } from 'react-redux'
import { photosHost } from '../../local'
import { orderActions } from '../../redux/order'

const styles = theme => ({
  card: {
    flexGrow: 1,
    margin: theme.spacing(1),
    position: 'relative',
    '& .MuiCardActionArea-root': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-between',
      alignItems: 'start',
    },
    '& img': {
      width: '100%',
    },
    '& svg': {
      width: '100%',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  product: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  form: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    alignItems: 'end',
    '& button': {
      height: '100%',
      '& svg': {
        width: 'auto',
      },
    },
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: 'rgba(150, 150, 150, 0.7)',
  },
  placeholder: {
    height: 48,
  },
  minusButton: {
    background: theme.palette.error.main,
    color: 'white',
  },
})

function ProductDialog(props) {
  const {
    price, open, onClose, classes, addToCart, role,
  } = props

  function change(event) {
    const { onChange } = props

    event.preventDefault()

    onChange(event.target.value)
  }

  const productPhoto = () => {
    if (price.photo) {
      return <img src={`${photosHost}/${price.photo}`} alt="Фото" />
    }

    return <div className={classes.placeholder} />
  }

  const stopBubble = event => {
    event.stopPropagation()
  }

  const onSubmit = event => {
    event.preventDefault()

    if (!Number.isNaN(Number(price.quantity))) {
      addToCart({
        product: price.product,
        unit: price.unit,
        quantity: Number(price.quantity),
        value: price.value,
        companies: price.companies,
        company: price.company,
      })
    }

    onClose(event)
  }

  const button = () => {
    if (Number(price.quantity) === Number(price.qty)) {
      return (
        <Button variant="contained" disabled>
          <CartIcon />
        </Button>
      )
    }

    if (price.quantity < price.qty) {
      return (
        <Button variant="contained" type="submit" onClick={onSubmit} className={classes.minusButton}>
          <CartIcon />
            -
        </Button>
      )
    }

    return (
      <Button variant="contained" color="secondary" type="submit" onClick={onSubmit}>
        <CartIcon />
          +
      </Button>
    )
  }

  return (
    <Backdrop open={open} onClick={onClose} className={classes.backdrop}>
      <Card className={classes.card} onClick={stopBubble}>
        <CardMedia
          component={productPhoto}
        />
        <CardContent>
          <div className={classes.product}>
            <Typography variant="body1" component="span">
              {price.product.name}
            </Typography>

            <Typography variant="body1" color="textSecondary" component="span">
              { role !== 'chef' ? `${price.value} тг/${price.unit.name}` : `Единица измерения: ${price.unit.name}` }
            </Typography>
          </div>

          <form className={classes.form} onSubmit={onSubmit}>
            <TextField
              label="Количество"
              InputProps={{
                endAdornment: <InputAdornment position="end">{price.unit.name}</InputAdornment>,
              }}
              value={price.quantity}
              type="text"
              onChange={change}
            />

            {button()}
          </form>
        </CardContent>

        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Card>
    </Backdrop>
  )
}

ProductDialog.propTypes = {
  price: PropTypes.objectOf(PropTypes.any),
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  addToCart: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

ProductDialog.defaultProps = {
  price: {
    product: {
      name: '',
    },
    unit: {
      name: ' ',
    },
  },
  open: false,
}

export default connect(null, {
  addToCart: orderActions.addToCart,
})(withStyles(styles)(ProductDialog))
