import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Fab,
  withStyles,
  ListItemIcon,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core'
import { Add as AddIcon, PhotoOutlined as PhotoIcon } from '@material-ui/icons'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { productActions } from '../../redux/product'
import { photosHost } from '../../local'

const styles = theme => ({
  root: {
    position: 'fixed',
    bottom: 0,
    top: 56,
    width: '100%',
    overflow: 'scroll',
    maxWidth: 600,
    margin: '0 auto',
    left: 0,
    right: 0,
    background: 'white',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  img: {
    width: '100%',
  },
})

class Products extends Component {
  componentDidMount() {
    const { apiSet, getProducts } = this.props

    if (apiSet) {
      getProducts()
    }
  }

  componentDidUpdate(prevProps) {
    const { apiSet, getProducts } = this.props

    if (apiSet && apiSet !== prevProps.apiSet) {
      getProducts()
    }
  }


  render() {
    const { products, classes } = this.props

    const productPhoto = photo => {
      if (photo) {
        return (
          <ListItemAvatar>
            <Avatar alt="Фото" src={`${photosHost}/${photo}`} variant="square" />
          </ListItemAvatar>
        )
      }

      return (
        <ListItemIcon>
          <PhotoIcon />
        </ListItemIcon>
      )
    }

    const productList = products.map(p => (
      <div key={p.id}>
        <ListItem button component={RouterLink} to={`/product/${p.id}`}>
          {productPhoto(p.photo)}

          <ListItemText primary={`${p.name}`} />
        </ListItem>
        <Divider />
      </div>
    ))

    return (
      <div className={classes.root}>
        <List>
          {productList}
        </List>

        <Fab color="secondary" className={classes.fab} component={RouterLink} to="/new-product">
          <AddIcon />
        </Fab>
      </div>
    )
  }
}

Products.propTypes = {
  apiSet: PropTypes.bool,
  getProducts: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
}

Products.defaultProps = {
  apiSet: false,
  products: [],
}

const mapStateToProps = state => ({
  apiSet: state.session.apiSet,
  products: state.product.products,
})

export default connect(mapStateToProps, {
  getProducts: productActions.getProducts,
})(withStyles(styles)(Products))
