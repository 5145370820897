import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  List, ListItem, ListItemText, Divider, Fab, withStyles, Tabs, Tab,
} from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { userActions } from '../../redux/user'
import { formatPhone } from '../../helpers'

const styles = theme => ({
  root: {
    position: 'fixed',
    bottom: 0,
    top: 56,
    width: '100%',
    overflow: 'scroll',
    maxWidth: 600,
    margin: '0 auto',
    left: 0,
    right: 0,
    background: 'white',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
})

class Users extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tab: 'active'
    }

    this.onChange = this.onChange.bind(this)
  }

  componentDidMount() {
    const { apiSet, getUsers } = this.props

    if (apiSet) {
      getUsers()
    }
  }

  componentDidUpdate(prevProps) {
    const { apiSet, getUsers } = this.props

    if (apiSet && apiSet !== prevProps.apiSet) {
      getUsers()
    }
  }

  onChange(event, tab) {
    this.setState({ tab: tab })
  }

  render() {
    let { users, classes } = this.props
    if (this.state.tab === 'archive')
      users = users.filter(u => u.isDisabled === true)
    else
      users = users.filter(u => u.isDisabled !== true)

    const userList = users.map(u => (
      <div key={u.id}>
        <ListItem button component={RouterLink} to={`/user/${u.id}`}>
          <ListItemText primary={`${u.name}, ${u.role}`} secondary={`+7 ${formatPhone(u.phone)}`} />
        </ListItem>
        <Divider />
      </div>
    ))

    return (
      <div className={classes.root}>
        <Tabs
          value={this.state.tab}
          variant="fullWidth"
          onChange={this.onChange}
        >
          <Tab value="active" label="Активные" />
          <Tab value="archive" label="Архив" />
        </Tabs>
        <List>
          {userList}
        </List>



        <Fab color="secondary" className={classes.fab} component={RouterLink} to="/new-user">
          <AddIcon />
        </Fab>
      </div>
    )
  }
}

Users.propTypes = {
  apiSet: PropTypes.bool,
  getUsers: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
}

Users.defaultProps = {
  apiSet: false,
  users: [],
}

const mapStateToProps = state => ({
  apiSet: state.session.apiSet,
  users: state.user.users,
})

export default connect(mapStateToProps, {
  getUsers: userActions.getUsers,
})(withStyles(styles)(Users))
