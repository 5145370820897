import * as types from './types'

export const getSuppliers = payload => ({
  type: types.GET_SUPPLIERS,
  payload,
})

export const change = payload => ({
  type: types.CHANGE_SUPPLIER,
  payload,
})

export const create = payload => ({
  type: types.CREATE_SUPPLIER,
  payload,
})

export const get = payload => ({
  type: types.GET_SUPPLIER,
  payload,
})

export const update = payload => ({
  type: types.UPDATE_SUPPLIER,
  payload,
})

export const getMembers = payload => ({
  type: types.GET_SUPPLIER_MEMBERS,
  payload,
})

export const changeMember = payload => ({
  type: types.CHANGE_SUPPLIER_MEMBER,
  payload,
})

export const createMember = payload => ({
  type: types.CREATE_SUPPLIER_MEMBER,
  payload,
})

export const getMember = payload => ({
  type: types.GET_SUPPLIER_MEMBER,
  payload,
})

export const updateMember = payload => ({
  type: types.UPDATE_SUPPLIER_MEMBER,
  payload,
})

export const getPrices = payload => ({
  type: types.GET_SUPPLIER_PRICES,
  payload,
})

export const changePrice = payload => ({
  type: types.CHANGE_SUPPLIER_PRICE,
  payload,
})

export const createPrice = payload => ({
  type: types.CREATE_SUPPLIER_PRICE,
  payload,
})

export const getPrice = payload => ({
  type: types.GET_SUPPLIER_PRICE,
  payload,
})

export const updatePrice = payload => ({
  type: types.UPDATE_SUPPLIER_PRICE,
  payload,
})
