import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  withStyles,
  List,
  ListItem,
  Typography,
} from '@material-ui/core'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { notificationActions } from '../../redux/notification'

const styles = theme => ({
  root: {
    position: 'fixed',
    bottom: 0,
    top: 56,
    width: '100%',
    overflow: 'scroll',
    maxWidth: 600,
    left: 0,
    right: 0,
    margin: '0 auto',
    background: 'white',
  },
  item: {
    flexDirection: 'column',
    color: '#000',
    alignItems: 'start',
  },
  notification: {
    display: 'flex',
    width: '100%',
    minHeight: '60px',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between',
  },
  notificationMessage: {
    display: 'block',
  },
  notificationInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
})

function Notifications({ apiSet, getNotifications, classes, notifications }) {
  useEffect(() => {
    if (apiSet) {
      getNotifications()
    }
  }, [apiSet, getNotifications])

  const notificationList = notifications.map(n => (
    <div key={n.id}>
      <Link to={`/order/${n.orderId}`} style={{ textDecoration: 'none' }}>
        <ListItem className={classes.item} divider>
          <div className={classes.notification}>
            <div className={classes.notificationMessage}>
              { n.message.match(/\n/) ? n.message.split('\n').map((a, i) => (
                <Typography variant="body2" key={i}>
                  { a }
                </Typography>
              )) : (
                <Typography variant="body2">
                  { n.message }
                </Typography>
              )}
            </div>
            <div className={classes.notificationInfo}>
              <Typography variant="body2">
                { new Date(n.sended).toLocaleString() }
              </Typography>
            </div>
          </div>

        </ListItem>
      </Link>
    </div>
  ))

  return (
    <div className={classes.root}>
      <List>
        { notificationList }
      </List>
    </div>
  )
}

Notifications.propTypes = {
  apiSet: PropTypes.bool,
  getNotifications: PropTypes.func.isRequired,
  notifications: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
}

Notifications.defaultProps = {
  apiSet: false,
  notifications: [],
}

const mapStateToProps = state => ({
  apiSet: state.session.apiSet,
  notifications: state.notification.notifications,
})

export default connect(mapStateToProps, {
  getNotifications: notificationActions.getNotifications,
})(withStyles(styles)(Notifications))
