import * as types from './types'

const initialState = {

}

export default function (state = initialState, action) {
  const { payload } = action

  switch (action.type) {
    case types.GET_CITIES:
      return { ...state }
    case types.GET_CITIES_SUCCESS:
      return {
        ...state,
        cities: payload,
      }
    case types.GET_CITIES_ERROR:
      return { ...state, error: payload.status }
    default:
      return { ...state, error: null }
  }
}
