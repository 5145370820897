import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  withStyles,
  Typography,
  List,
  ListItem,
  TextField,
  InputAdornment,
  Divider,
  Fab,
  Button,
  ThemeProvider,
  createMuiTheme,
} from '@material-ui/core'
import { Check as CheckIcon } from '@material-ui/icons'
import { connect } from 'react-redux'
import { withRouter, useLocation } from 'react-router-dom'
import { orderActions } from '../../redux/order'
import OrderDialog from './OrderDialog'
import OrderChangesDialog from './OrderChangesDialog'
import api from '../../api/api'
import { sessionActions } from '../../redux/session'
import { describeStatus } from '../../helpers'

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    width: '100%',
    margin: '60px auto',
    maxWidth: 600,
    background: 'white',
  },
  button: {
    width: '100%',
    top: theme.spacing(4),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  form: {
    '& .MuiFormControl-root': {
      width: '100%',
      marginBottom: theme.spacing(3),
    },
    '& .MuiButton-root': {
      marginTop: theme.spacing(3),
    },
  },
  fileInput: {
    display: 'none',
  },
  img: {
    width: '100%',
  },
  float: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  order: {
    marginTop: 0,
    justifySelf: 'end',
  },
  comment: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  commentText: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  item: {
    flexDirection: 'column',
    alignItems: 'start',
    marginBottom: theme.spacing(1),
  },
})

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        marginTop: 0,
        fontSize: '0.875rem',
        borderRadius: 3,
        color: 'white',
        height: 36,
        padding: '0 20px',
      },
    },
  },
})

function Order({
  apiSet,
  get,
  match,
  update,
  details,
  status,
  role,
  change,
  additional,
  changeDetails,
  original,
  classes,
  user,
  supplier,
  branch,
  comment,
  setAPIAuth,
  getUser,
}) {
  const { search } = useLocation()
  let c = ''
  if (search) {
    c = search.slice(3)
  }

  useEffect(() => {
    if (apiSet) {
      get({ id: match.params.id })
    } else if (c) {
      api.defaults.headers.common.Authorization = c

      setAPIAuth(true)

      getUser()

      get({ id: match.params.id })
    }
  }, [apiSet, get, match.params.id, c, setAPIAuth, getUser])

  const [state, setState] = useState({
    open: false,
    changesDialog: false,
  })

  const { open, statusForDialog, changesDialog } = state

  const onSubmit = (event) => {
    event.preventDefault()

    update({
      id: match.params.id,
      details,
      status,
      comment,
    })
  }

  const closeDialog = event => {
    event.preventDefault()

    setState({
      open: false,
      changesDialog: false,
    })
  }

  const changeQty = (item, event) => {
    // Prevent supplier from increasing quantity, only allow decrease
    const originalQuantity = original.details.find(det => det.id === item.id).quantity
    if (role === 'supplier' && event.target.value > originalQuantity) {
      return
    }

    changeDetails({
      ...item,
      quantity: event.target.value,
    })
  }

  const changeValue = (item, event) => {
    changeDetails({
      ...item,
      value: event.target.value,
    })
  }

  const onAcceptShipment = (_event) => {
    setState({
      open: true,
      statusForDialog: 'acquired',
    })
  }

  const onReturnShipment = (_event) => {
    setState({
      open: true,
      statusForDialog: 'returned',
    })
  }

  const onAcquireChanges = () => {
    setState({
      changesDialog: true,
      statusForDialog: 'changes_acquired',
    })
  }

  const onRejectChanges = () => {
    setState({
      changesDialog: true,
      statusForDialog: 'changes_rejected',
    })
  }

  const formChanged = () => {
    if (status !== original.status) {
      return true
    }

    if (!_.isEqual(details, original.details)) {
      return true
    }

    return false
  }

  const handleAdditionalOrder = (e) => {
    additional({
      details: e,
    })
  }

  const detailList = details.map(d => (
    <div key={d.id}>
      <ListItem className={classes.item}>
        <div className={classes.float}>
          <Typography variant="body1">
            {`${d.product.name}`}
          </Typography>

          {role !== 'chef' ? (
            <Typography variant="body2">
              {`${d.value * d.quantity} тг`}
            </Typography>
          ) : null}
        </div>

        <TextField
          label="Количество"
          InputProps={{
            endAdornment: <InputAdornment position="end">{d.unit.name}</InputAdornment>,
          }}
          value={d.quantity}
          type="number"
          onChange={event => changeQty(d, event)}
          className={classes.input}
          disabled={status !== 'new'}
        />

        {role !== 'chef' ? (
          <TextField
            label="Цена"
            InputProps={{
              endAdornment: <InputAdornment position="end">тг</InputAdornment>,
            }}
            value={d.value}
            type="number"
            onChange={event => changeValue(d, event)}
            className={classes.input}
            disabled={role === 'supplier'}
          />
        ) : null}

        { (role !== 'supplier' && role !== 'chef') && (status === 'changed' || status === 'changes_acquired') ? (
          <div className={classes.float}>
            <ThemeProvider theme={theme}>
              <Button variant="contained" color="primary" onClick={() => handleAdditionalOrder(details)}>
                Дозаказ
              </Button>
            </ThemeProvider>
          </div>
        ) : null
        }
      </ListItem>
      <Divider />
    </div>
  ))

  const total = details.reduce((sum, d) => sum + d.quantity * d.value, 0)

  const prices = role !== 'chef' ? (
    <div className={classes.float}>
      <Typography variant="body2">
        Общая сумма
      </Typography>

      <Typography variant="body1">
        {`${total} тг`}
      </Typography>
    </div>
  ) : null

  return (
    <div className={classes.root}>
      <form className={classes.form}>
        <div className={classes.float}>
          <Typography variant="body2">
            Автор
          </Typography>

          <Typography variant="body1">
            {user}
          </Typography>
        </div>

        <div className={classes.float}>
          <Typography variant="body2">
            Поставщик
          </Typography>

          <Typography variant="body1">
            {supplier}
          </Typography>
        </div>

        <div className={classes.float}>
          <Typography variant="body2">
            Объект
          </Typography>

          <Typography variant="body1">
            {branch}
          </Typography>
        </div>

        <div className={classes.float}>
          <Typography variant="body2">
            Статус
          </Typography>

          <Typography variant="body1">
            {describeStatus(status).text}
          </Typography>
        </div>
        {prices}
        <Typography variant="body1">
          Комментарий
        </Typography>
        <div className={classes.comment}>
          <Typography variant="body1" className={classes.commentText}>
            {`${comment || ''}`}
          </Typography>
        </div>

        <Typography variant="body1">
          Детали
        </Typography>

        <List>
          {detailList}
        </List>

        {
          role !== 'supplier' && (status === 'accepted' || status === 'changes_acquired') ? (
            <>
              <Button variant="contained" color="secondary" className={classes.button} onClick={event => onAcceptShipment(event)}>
                Принять товар
              </Button>
              <Button variant="contained" className={classes.button} onClick={event => onReturnShipment(event)}>
                Возврат товара
              </Button>
            </>
          ) : null
        }

        {
          role !== 'supplier' && status === 'changed' ? (
            <>
              <Button variant="contained" color="secondary" className={classes.button} onClick={event => onAcquireChanges(event)}>
                Принять изменения
              </Button>
              <Button variant="contained" className={classes.button} onClick={event => onRejectChanges(event)}>
                Отклонить изменения
              </Button>
            </>
          ) : null
        }

        <Fab color="secondary" className={classes.fab} onClick={onSubmit} disabled={!formChanged()}>
          <CheckIcon />
        </Fab>
      </form>
      <OrderDialog
        open={open}
        onClose={closeDialog}
        onChange={values => change({ ...values, orderID: match.params.id })}
        status={statusForDialog}
      />
      <OrderChangesDialog
        open={changesDialog}
        onClose={closeDialog}
        status={statusForDialog}
      />
    </div>
  )
}

Order.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  get: PropTypes.func.isRequired,
  apiSet: PropTypes.bool,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.string,
  supplier: PropTypes.string,
  branch: PropTypes.string,
  status: PropTypes.string,
  details: PropTypes.arrayOf(PropTypes.any),
  change: PropTypes.func.isRequired,
  changeDetails: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  role: PropTypes.string,
  original: PropTypes.objectOf(PropTypes.any),
  comment: PropTypes.string,
  setAPIAuth: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
}

Order.defaultProps = {
  apiSet: false,
  user: '',
  supplier: '',
  branch: '',
  status: '',
  details: [],
  role: '',
  original: {},
  comment: '',
}

const mapStateToProps = state => ({
  apiSet: state.session.apiSet,
  user: state.order.user,
  supplier: state.order.supplier,
  branch: state.order.branch,
  status: state.order.status,
  comment: state.order.comment,
  details: state.order.details,
  role: state.session.role,
  original: state.order.original,
})

export default withRouter(connect(mapStateToProps, {
  get: orderActions.get,
  change: orderActions.change,
  changeDetails: orderActions.changeDetails,
  update: orderActions.update,
  setAPIAuth: sessionActions.setAPIAuth,
  getUser: sessionActions.get,
  additional: orderActions.createAdditionalOrder,
})(withStyles(styles)(Order)))
