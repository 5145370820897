import * as types from './types'

const initialState = {
  message: null,
  open: false,
  notification: {},
}

export default function (state = initialState, action) {
  const { payload } = action

  switch (action.type) {
    case types.SET_NOTIFICATION_OPEN:
      const message = payload ? state.message : null

      return { ...state, open: payload, message }
    case types.NOTIFY:
      return { ...state, message: payload }
    case types.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: payload,
        error: null,
      }
    case types.GET_NOTIFICATIONS_ERROR:
      return { ...state, error: payload.status }
    default:
      return { ...state }
  }
}
