import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  List,
  ListItem,
  Typography,
  Divider,
  Fab,
  withStyles,
  IconButton,
} from '@material-ui/core'
import { Add as AddIcon, Telegram as TelegramIcon } from '@material-ui/icons'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { orderActions } from '../../redux/order'
import MainOrders from './MainOrders'
import { describeStatus } from '../../helpers'

const styles = (theme) => ({
  root: {
    position: 'fixed',
    bottom: 0,
    top: 56,
    width: '100%',
    overflow: 'scroll',
    maxWidth: 600,
    left: 0,
    right: 0,
    margin: '0 auto',
    background: 'white',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  img: {
    width: '100%',
  },
  float: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'end',
  },
  mainOrder: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mainOrderDate: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  mainOrderLink: {
    textDecoration: 'none',
    color: 'inherit',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  mainOrderInfo: {
    flex: '0.9',
  },
  item: {
    flexDirection: 'column',
    alignItems: 'start',
  },
  itemLink: {
    textDecoration: 'none',
    color: 'inherit',
    width: '100%',
  },
  warning: {
    color: theme.palette.warning.main,
  },
  success: {
    color: theme.palette.warning.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  secondary: {
    color: theme.palette.text.secondary,
  },
  telegram: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '120px',
    width: '100%',
  },
})

function Orders({
  apiSet,
  getOrders,
  send,
  sendToArchive,
  classes,
  role,
  orders,
  openedOrders,
  setOpen,
}) {
  useEffect(() => {
    if (apiSet) {
      getOrders()
    }
  }, [apiSet, getOrders])

  const sendOrder = (order) =>
    send({
      id: order.id,
      telegrams: order.telegrams,
    })

  const archive = (orderId) => {
    sendToArchive({
      id: orderId,
    })
  }

  const statusText = (status) => (
    <Typography
      variant="body2"
      className={classes[describeStatus(status).color]}
    >
      {describeStatus(status).text}
    </Typography>
  )

  const mainStatus = (rs) => {
    if (rs.every((el) => el.status === 'new')) {
      return (
        <Typography variant="body2" className={classes.success}>
          Создана
        </Typography>
      )
    }
    if (
      rs.every(
        (el) =>
          el.status === 'rejected' ||
          el.status === 'changes_rejected' ||
          el.status === 'acquired',
      )
    ) {
      return (
        <Typography variant="body2" className={classes.secondary}>
          Закрыта
        </Typography>
      )
    }

    return (
      <Typography variant="body2" className={classes.warning}>
        В процессе
      </Typography>
    )
  }

  const setOpenedOrder = (opened) => {
    setOpen({
      openedOrders: opened,
    })
  }

  const orderList = (rs) =>
    rs.map((order) => {
      const createDate = new Date(order.createDate)

      const TelegramButton = () => {
        if (
          role === 'supplier' ||
          role === 'chef' ||
          !order.telegrams ||
          order.telegrams.length === 0
        ) {
          return null
        }

        return (
          <IconButton
            color="primary"
            disabled={order.sent}
            onClick={(event) => sendOrder(order, event)}
          >
            <TelegramIcon />
          </IconButton>
        )
      }

      return (
        <div key={order.id}>
          <ListItem className={classes.item}>
            <RouterLink to={`/order/${order.id}`} className={classes.itemLink}>
              <Typography variant="body1">
                {`#${order.number}. ${order.supplier}`}
              </Typography>
              <div className={classes.float}>
                <Typography variant="body2">
                  {createDate.toLocaleString()}
                </Typography>
                <Typography variant="body2">{order.branch}</Typography>
              </div>
            </RouterLink>
            <div className={classes.telegram}>
              {statusText(order.status)}

              <div>{TelegramButton()}</div>
            </div>
          </ListItem>

          <Divider />
        </div>
      )
    })

  const mainOrders = orders
    .filter((order) => order.archived !== true)
    .map((mainOrder) => (
      <MainOrders
        statusText={mainStatus}
        key={mainOrder.id}
        orderList={orderList}
        classes={classes}
        mainOrder={mainOrder}
        archive={archive}
        orderId={mainOrder.id}
        openedOrder={openedOrders}
        setOpened={setOpenedOrder}
      />
    ))
  return (
    <div className={classes.root}>
      <List>{mainOrders}</List>

      {role !== 'supplier' ? (
        <Fab
          color="secondary"
          className={classes.fab}
          component={RouterLink}
          to="/market"
        >
          <AddIcon />
        </Fab>
      ) : null}
    </div>
  )
}

Orders.propTypes = {
  apiSet: PropTypes.bool,
  getOrders: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  orders: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  role: PropTypes.string,
  send: PropTypes.func.isRequired,
  sendToArchive: PropTypes.func.isRequired,
  openedOrders: PropTypes.arrayOf(PropTypes.number),
}

Orders.defaultProps = {
  apiSet: false,
  orders: [],
  role: '',
  openedOrders: [],
}

const mapStateToProps = (state) => ({
  apiSet: state.session.apiSet,
  orders: state.order.orders,
  role: state.session.role,
  openedOrders: state.order.openedOrders,
})

export default connect(mapStateToProps, {
  getOrders: orderActions.getOrders,
  send: orderActions.send,
  sendToArchive: orderActions.sendToArchive,
  setOpen: orderActions.setOpenedMainOrders,
})(withStyles(styles)(Orders))
