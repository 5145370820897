import * as types from './types'

export const getUsers = payload => ({
  type: types.GET_USERS,
  payload,
})

export const change = payload => ({
  type: types.CHANGE_USER,
  payload,
})

export const create = payload => ({
  type: types.CREATE_USER,
  payload,
})

export const get = payload => ({
  type: types.GET_USER,
  payload,
})

export const update = payload => ({
  type: types.UPDATE_USER,
  payload,
})

export const archive = payload => ({
  type: types.DELETE_USER,
  payload,  
})