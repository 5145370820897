import sessionSaga from './session/saga'
import userSaga from './user/saga'
import branchSaga from './branch/saga'
import supplierSaga from './supplier/saga'
import citySaga from './city/saga'
import productSaga from './product/saga'
import orderSaga from './order/saga'
import notificationSaga from './notification/saga'

export default [sessionSaga, userSaga, branchSaga, supplierSaga, citySaga, productSaga, orderSaga, notificationSaga]
