import React from 'react'
import PropTypes from 'prop-types'
import {
  List,
  ListItem,
  Typography,
  Collapse, IconButton,
} from '@material-ui/core'
import {
  Archive as ArchiveIcon,
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons'
import { Link as RouterLink } from 'react-router-dom'

const MainOrders = ({ mainOrder, orderList, classes, statusText, archive, orderId, openedOrder, setOpened }) => {

  const handleClick = (order) => {
    if (!openedOrder.includes(order)) {
      const opened = [...openedOrder]
      opened.push(order)
      setOpened(opened)
    } else {
      const opened = [...openedOrder]
      const index = openedOrder.indexOf(order)
      opened.splice(index, 1)
      setOpened([...opened])
    }
  }
  const createDate = new Date(mainOrder.createDate)

  return (
    <div>
      <ListItem className={classes.item}>
          <div className={classes.mainOrder}>
            <RouterLink to={`/main-order/${mainOrder.id}`} className={classes.mainOrderLink}>
              <div className={classes.mainOrderInfo}>
                <Typography variant="body1">
                  {`#${mainOrder.number}.`}
                </Typography>
                <Typography variant="body1">
                  {`${mainOrder.branche?.name}`}
                </Typography>
                <Typography variant="body2" className={classes.mainOrderDate}>
                  {createDate.toLocaleString()}
                </Typography>

              </div>
              <div>
                {statusText(mainOrder.orders)}
              </div>
            </RouterLink>

            {
              archive && orderId && (mainOrder.orders.every(el => el.status === 'rejected'
                || el.status === 'acquired')) ? (
                  <div className={classes.buttons}>
                    <IconButton onClick={() => handleClick(mainOrder.number)}>
                      { openedOrder.includes(mainOrder.number) ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>

                    <IconButton onClick={() => archive(orderId)}>
                      <ArchiveIcon />
                    </IconButton>
                  </div>
                ) :
                (
                  <IconButton onClick={() => handleClick(mainOrder.number)}>
                    { openedOrder.includes(mainOrder.number) ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                )
            }

          </div>
      </ListItem>
      <Collapse in={openedOrder.includes(mainOrder.number)} timeout="auto" unmountOnExit>
        <List className={classes.nested} component="div" disablePadding>
          {orderList(mainOrder.orders)}
        </List>
      </Collapse>
    </div>
  )
}

MainOrders.propTypes = {
  mainOrder: PropTypes.objectOf(PropTypes.any),
  orderList: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  statusText: PropTypes.func.isRequired,
  orderId: PropTypes.string,
  archive: PropTypes.func.isRequired,
  openedOrder: PropTypes.arrayOf(PropTypes.number),
  setOpened: PropTypes.func.isRequired,
}

MainOrders.defaultProps = {
  mainOrder: null,
  orderId: null,
  openedOrder: [],
}

export default MainOrders
