export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR'

export const LOGOUT_USER = 'LOGOUT_USER'

export const SET_API_AUTH = 'SET_API_AUTH'

export const GET_SESSION = 'GET_SESSION'
export const GET_SESSION_SUCCESS = 'GET_SESSION_SUCCESS'
export const GET_SESSION_ERROR = 'GET_SESSION_ERROR'

export const SET_PASSWORD = 'SET_PASSWORD'
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS'
export const SET_PASSWORD_ERROR = 'SET_PASSWORD_ERROR'

export const CLEAR_SESSION = 'CLEAR_SESSION'
