import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Fab,
  withStyles,
  TextField,
  InputAdornment,
  Typography,
  FormControlLabel,
  Checkbox,
  Button
} from '@material-ui/core'
import { Check as CheckIcon } from '@material-ui/icons'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { supplierActions } from '../../redux/supplier'
import { userActions } from '../../redux/user'
import { branchActions } from '../../redux/branch'
import PhoneInput from '../../components/PhoneInput'

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    position: 'fixed',
    bottom: 0,
    top: 56,
    width: '100%',
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  button: {
    width: '100%',
    top: theme.spacing(4),
  },
  form: {
    '& .MuiFormControl-root': {
      width: '100%',
      marginBottom: theme.spacing(3),
    },
    '& .MuiButton-root': {
      marginTop: theme.spacing(3),
    },
  },
})

class Member extends Component {
  constructor(props) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
    this.formValid = this.formValid.bind(this)
    this.onCheckboxChange = this.onCheckboxChange.bind(this)
    this.onArchiveUser = this.onArchiveUser.bind(this)
  }

  componentDidMount() {
    const {
      id,
      history,
      location,
      apiSet,
      get,
      match,
      getBranches,
    } = this.props

    if (id && location.pathname === '/new-member') {
      history.replace(`/member/${id}`)
    }

    if (apiSet) {
      getBranches()

      if (match.params.id && !id) {
        get({ id: match.params.id })
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      id,
      history,
      location,
      get,
      match,
      apiSet,
      getBranches,
    } = this.props

    if (id && location.pathname === '/new-member') {
      history.replace(`/member/${id}`)
    }

    if (apiSet && apiSet !== prevProps.apiSet) {
      getBranches()

      if (match.params.id && !id) {
        get({ id: match.params.id })
      }
    }
  }

  onSubmit(event) {
    event.preventDefault()
    const {
      create, update, location, id, name, phone, supplyBranches, company,
    } = this.props

    const member = {
      id, name, phone, supplyBranches, company,
    }

    if (location.pathname === '/new-member') {
      return create(member)
    }

    return update(member)
  }

  onCheckboxChange(event) {
    event.preventDefault()

    const { supplyBranches, change } = this.props

    const newBranches = supplyBranches.filter(sbr => sbr !== event.target.value)

    if (newBranches.length === supplyBranches.length) {
      newBranches.push(event.target.value)
    }

    change({
      name: 'supplyBranches',
      value: newBranches,
    })
  }

  onArchiveUser(event) {
    event.preventDefault()

    const { archive, id, history } = this.props
    const user = { id }

    archive(user)
    history.go(-1)
  }

  formValid() {
    const { name, phone } = this.props

    if (!name) return false
    if (!phone) return false

    return true
  }

  render() {
    const {
      classes, change, name, phone, branches, supplyBranches, isDisabled, location
    } = this.props

    const isError = _field => {
      let helperText

      // if (field === 'phone' && error === 404) {
      //   helperText = 'Номер не найден'
      // }

      // if (field === 'password' && error === 403) {
      //   helperText = 'Неверный пароль'
      // }

      return helperText
    }

    const onFieldChange = fieldName => event => {
      change({
        name: fieldName,
        value: event.target.value,
      })
    }

    const checkedBranches = branches.map(br => ({
      ...br,
      checked: supplyBranches.some(sbr => sbr === br.id),
    }))

    return (
      <div className={classes.root}>
        <form onSubmit={this.onSubmit} className={classes.form}>
          <TextField error={!!isError('name')} disabled={isDisabled} label="Имя" value={name} onChange={onFieldChange('name')} helperText={isError('name')} />
          <TextField
            error={!!isError('phone')}
            label="Телефон"
            disabled={isDisabled}
            value={phone}
            onChange={onFieldChange('phone')}
            helperText={isError('phone')}
            InputProps={{
              startAdornment: <InputAdornment position="start">+7</InputAdornment>,
              inputComponent: PhoneInput,
            }}
          />

          <Typography variant="subtitle2">Объекты снабжения</Typography>

          {checkedBranches.map(br => (
            <FormControlLabel
              control={(
                <Checkbox
                  checked={br.checked}
                  disabled={isDisabled}
                  onChange={this.onCheckboxChange}
                  value={br.id}
                  color="primary"
                />
              )}
              label={br.name}
              key={br.id}
            />
          ))}


          {!isDisabled ?
            <Fab color="secondary" className={classes.fab} disabled={!this.formValid()} type="submit">
              <CheckIcon />
            </Fab>
          : null}
        </form>

        {!isDisabled ? (location.pathname !== '/new-member' ?
          <Button variant="contained" className={classes.button} onClick={this.onArchiveUser}>
            Удалить представителя
          </Button> : null)
        : <Typography variant="subtitle">Представитель заархивирован</Typography> }
      </div>
    )
  }
}

Member.propTypes = {
  apiSet: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  change: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
  company: PropTypes.string,
  getBranches: PropTypes.func.isRequired,
  branches: PropTypes.arrayOf(PropTypes.object),
  supplyBranches: PropTypes.arrayOf(PropTypes.string),
}

Member.defaultProps = {
  apiSet: false,
  id: null,
  name: '',
  phone: '',
  branches: [],
  supplyBranches: [],
  company: null,
  isDisabled: false
}

const mapStateToProps = state => ({
  apiSet: state.session.apiSet,
  id: state.supplier.member.id,
  name: state.supplier.member.name,
  phone: state.supplier.member.phone,
  company: state.supplier.id,
  branches: state.branch.branches,
  supplyBranches: state.supplier.member.supplyBranches,
  isDisabled: state.supplier.member.isDisabled
})

export default withRouter(connect(mapStateToProps, {
  get: supplierActions.getMember,
  archive: userActions.archive,
  change: supplierActions.changeMember,
  create: supplierActions.createMember,
  update: supplierActions.updateMember,
  getBranches: branchActions.getBranches,
})(withStyles(styles)(Member)))
